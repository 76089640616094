<div class="stepper-container">
  <div
    class="step"
    *ngFor="let step of steps"
    [ngClass]="{
      active: activeStep === step.name,
      completed: step.completed
    }"
  >
    <div class="status-line"></div>
    <div class="step-info">
      <div class="status-icon">
        <app-icon *ngIf="activeStep === step.name"
          >radio_button_checked
        </app-icon>
        <app-icon *ngIf="!step.completed && activeStep !== step.name"
          >radio_button_unchecked</app-icon
        >
        <app-icon
          iconStyle="filled"
          *ngIf="step.completed && activeStep !== step.name"
          >check_circle</app-icon
        >
      </div>
      <div class="step-title">{{ step.displayName }}</div>
    </div>
  </div>
</div>
