import {
  Component, EventEmitter, Input, OnDestroy, OnInit, Output,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import UserGenerator from 'src/app/classes/user.class';
import { VerificationComponent } from 'src/app/components/verification/verification.component';
import { AuthService } from 'src/app/services/auth.service';
import { ToastService } from 'src/app/services/toast.service';
import { UsersService } from 'src/app/services/users.service';

type AssetType = 'buildings' | 'positions' | 'roles' | 'primaryApprovers' | 'secondaryApprovers' | 'requirements';

@Component({
  selector: 'app-review-and-finish',
  templateUrl: './review-and-finish.component.html',
  styleUrls: [ './review-and-finish.component.scss' ],
})
export class ReviewAndFinishComponent implements OnInit, OnDestroy {
  @Output() updateUserObject = new EventEmitter<object>();
  @Output() stepBack = new EventEmitter<any>();
  @Input() user: UserGenerator;
  @Input() isEditMode: any;
  @Input() step: { text: string; completed: boolean; modified: boolean; }

  @Input() buildingsList: any[];
  @Input() positionsList: any[];
  @Input() rolesList: any[];
  @Input() requirementsList: any[];
  @Input() primaryApproversList: any[];
  @Input() secondaryApproversList: any[];

  userSubscription: any;
  error: string;
  isLoading = false;

  requestBody: any = {};
  disableFinishButton = false;

  constructor(
    public dialog: MatDialog,
    private usersService: UsersService,
    private toastService: ToastService,
    public dialogRef: MatDialogRef<ReviewAndFinishComponent>,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.requestBody = this.user.generateRequest();

    if (Object.keys(this.requestBody).length === 0) {
      this.disableFinishButton = true;
    }
  }

  stepBackward = () => {
    this.stepBack.emit();
  }

  closeDialog = () => {
    this.dialog.closeAll();
  }

  getRoleName() {
    const userRole = this?.user?.roleIds?.[0];

    if (!userRole) return '-';

    const roleName = this.rolesList?.find((role: any) => {
      return role.id == userRole;
    })?.roleName;


    return roleName || '-';
  }

  public saveUser = async () => {
    // final validation check before sending request
    if (
      this.user.firstName === '' ||
      this.user.lastName === '' ||
      this.user.contactEmail === '' ||
      !this.user.primaryBuildingId ||
      !this.user.primaryPositionId ||
      this.user.roleIds?.length === 0 ||
      this.user?.primaryApproverIds.length === 0
      || this.user?.secondaryApproverIds.length === 0
    ) {
      this.error = 'Please go back and fill out all required fields';
      return
    }
    this.isLoading = true;

    if (!this.user.prevValues) {
      await this.authService.addUserToAWS(this.user.contactEmail);
    }

    this.userSubscription = (!this.isEditMode ?
      this.usersService.addUser(this.requestBody)
      : this.usersService.updateUser(this.requestBody, this.user.id as number)
    )
      .subscribe({
        next: (response: any) => {
          this.usersService.clearSelectedUser()
          // here we get the updated user if we are in edit mode, mostly for when editing from the profile page
          this.user.id ? this.usersService.getUserById(this.user.id, { 'includedAssociations': 'PrimaryBuilding, PrimaryPosition, Requirements, Roles' }) : null;

          const message = this.isEditMode ? 'User successfully updated' : 'User successfully added';

          this.toastService.setToast({ text: message, type: 'success' })
          this.isLoading = false;
          this.dialogRef.close(response);
        },
        error: (error: any) => {
          this.isLoading = false;
          console.log('error', error);
          let message;
          if (error.error.statusCode === 3002) {
            message = 'Email already exists, please choose another email'
          }
          this.dialog.open(VerificationComponent, {
            data: {
              type: 'alert', title: 'Oops, something went wrong', text: message || error.error.errMsg || error.error.message,
            },
          })
        },
      })
  }

  // #region GET ASSET INFO
  getAssets(
    assetListType: AssetType, assetIds: number[], primaryId?: number,
  ) {
    const formattedAssetIds = assetIds.map((id) => Number(id));
    const returnObj: { primaryAsset: any, secondaryAssets: any[]} = {
      primaryAsset: {},
      secondaryAssets: [],
    }
    const assetArrRef = `${assetListType}List`;
    // @ts-ignore
    const assetList = this[assetArrRef];
    const assets = assetList?.filter((asset: any) => {
      return formattedAssetIds.includes(Number(asset.id)) ||
      formattedAssetIds.includes(Number(asset?.userId));
    })

    if (!assets) return [ returnObj ];

    if (primaryId) {
      const primaryAsset = assets?.find((asset: any) => asset.id == primaryId);

      returnObj.primaryAsset = primaryAsset;
      const secondaryAssets = assets?.filter((asset: any) => asset.id != primaryId);
      returnObj.secondaryAssets = secondaryAssets;
    } else {
      returnObj.secondaryAssets = assets;
    }

    return [ returnObj ];
  }

  // #endregion GET ASSET INFO

  ngOnDestroy(): void {
    this.userSubscription?.unsubscribe();
  }

}
