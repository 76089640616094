<div class="container">
  <div class="content">
    <div class="title-row">
      <span class="ktitle-tiny">User Assignments</span>
      <span class="klabel-medium">*Required field</span>
    </div>
    <span class="kbody-large description"
      >Users need to have at least one of each assignment.</span
    >
    <span class="klabel-huge">Primary</span>
    <span class="klabel-medium label">Select primary*</span>
    <div class="input-select-container kinput">
      <input
        #administratorinput
        (change)="addApprovers($event, true)"
        class="kinput kbody-medium input-select"
        type="text"
        placeholder="Type or select an approver"
        list="targets"
      />
      <datalist id="targets" class="datalist">
        <option
          *ngFor="let approver of filteredPrimaryApprovers"
          [value]="approver.userId"
          class="kbody-medium"
        >
          {{ approver.fullName }}
        </option>
      </datalist>
    </div>
    <span
      class="klabel-medium label"
      *ngIf="(user?.primaryApproverIds)!.length > 0"
      >Selections</span
    >
    <div class="chips">
      <app-chip
        class="outlined"
        *ngFor="let approverId of user?.primaryApproverIds"
        (closeChip)="removeApprover(approverId)"
        [closable]="true"
        [text]="getApproverName(approverId)"
      ></app-chip>
    </div>

    <span class="klabel-huge">Secondary</span>
    <span class="klabel-medium label">Select secondary*</span>
    <div class="input-select-container kinput">
      <input
        #supervisorinput
        (change)="addApprovers($event, false)"
        class="kinput kbody-medium input-select"
        type="text"
        placeholder="Type or select an approver"
        list="supervisortargets"
      />
      <datalist id="supervisortargets" class="datalist">
        <option
          class="kbody-medium"
          *ngFor="let secondaryApprover of filteredSecondaryApprovers"
          [value]="secondaryApprover.userId"
        >
          {{ secondaryApprover.fullName }}
        </option>
      </datalist>
    </div>
    <span
      class="klabel-medium label"
      *ngIf="(user?.secondaryApproverIds)!.length > 0"
      >Selections</span
    >
    <div class="chips">
      <app-chip
        class="outlined"
        *ngFor="let approverId of user.secondaryApproverIds"
        (closeChip)="removeApprover(approverId, false)"
        [closable]="true"
        [text]="getApproverName(approverId, false)"
      ></app-chip>
    </div>
  </div>
  <div class="buttons">
    <app-button
      (click)="stepBackward()"
      [text]="'Previous Step'"
      [icon]="{ materialIcon: 'arrow_back' }"
      [class]="'white'"
    ></app-button>
    <div class="actions">
      <app-button
        [text]="'Cancel'"
        [class]="'white'"
        (click)="closeDialog()"
      ></app-button>
      <app-button
        [disabled]="
          this.user.primaryApproverIds.length === 0 ||
          this.user.secondaryApproverIds.length === 0
        "
        [icon]="{ materialIcon: 'arrow_forward' }"
        [class]="'icon-right'"
        [text]="'Next Step'"
        (click)="updateUser()"
      ></app-button>
    </div>
  </div>
</div>
