import {
  Component, EventEmitter, Input, Output,
} from '@angular/core';
import { NewTableData } from '../../new-table/new-table.component';
import { NewButtonComponent } from '../button/button.component';

export type ContentContainerNavButtonGroup = {
  display: string;
  name: string;
  leftIcon?: string;
  rightIcon?: string;
}

export interface ContentContainerButtonGroup extends ContentContainerNavButtonGroup {
  size?: NewButtonComponent['size'];
  color?: NewButtonComponent['color'];
  elevated?: NewButtonComponent['elevated'];
  disabled?: NewButtonComponent['disabled'];
  onClick?: () => void;
}


@Component({
  selector: 'app-content-container',
  templateUrl: './content-container.component.html',
  styleUrls: [ './content-container.component.scss' ],
})
export class ContentContainerComponent {
  @Input() title: string;
  @Input() titleButtonGroup: ContentContainerButtonGroup[] = [];

  @Input() contentNavOptions?: ContentContainerButtonGroup[] = [];
  @Input() paginationMeta: NewTableData['meta'];

  @Output() navClicked = new EventEmitter<string>();

  @Output() buttonClicked = new EventEmitter<string>();


  onNavOptionClicked = (name: string) => {
    this.navClicked.emit(name);
  }

  clickHandler = (name: string) => {
    this.buttonClicked.emit(name);
  }

}
