import {
  Component, Inject, Input, OnInit, ViewChild,
} from '@angular/core';
import {
  MAT_DIALOG_DATA, MatDialog, MatDialogRef,
} from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { PositionsService } from 'src/app/services/positions.service';

@Component({
  selector: 'app-export-settings',
  templateUrl: './export-settings.component.html',
  styleUrls: [ './export-settings.component.scss' ],
})
export class ExportSettingsComponent implements OnInit {
  @ViewChild('positioninput') positioninput: any;
  positionDataSubscription = new Subscription();
  positionsData$: Observable<any>;
  positions = []
  selectedPositions = []
  sideTabs = [
    {
      name: 'File Output',
      icon: 'file_open',
      description: 'By default, when you export this report, the column headers will be the requirement selections you make in step two. You can create new templates or edit current templates here.',
    }, {
      name: 'Position Exclusions',
      icon: 'work',
      description: 'Adding positions to exclude here will disable any user within the selected position to appear on the exported file.',
    }, {
      name: 'User Exclusions',
      icon: 'person',
      description: 'Adding users to exclude here will disable the selected users from appearing on the exported file.',
    },
  ]

  fileOutputTemplateOptions = [
    {
      value: 'system default',
      text: 'System Default',
    }, {
      value: 'moecs',
      text: 'MOECS Export',
    },
  ]
  selectedTab = this.sideTabs[0]
  settings = {
    includePreviouslyExportedUserData: false,
    groupByExportDate: false,
  }

  constructor(
    private positionsService: PositionsService,
    public dialogRef: MatDialogRef<ExportSettingsComponent>,
    public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    console.log('ngOnInit')
    this.getPositions()
  }

  getPositions = () => {
    this.positionsData$ = this.positionsService.positionsData$
    const params = {
      positionTitleFilter: '',
      activeFlag: 1 as 0 | 1,
      sortColumn: 'positionTitle',
      sortDirection: 'ASC' as 'ASC' | 'DESC',
      limit: 10,
      offset: 0,
    };
    this.positionsService.getPositions(params)
    this.positionDataSubscription = this.positionsData$.subscribe(data => {
      if (!data) return;
      this.positions = data.rows;
      console.log('positions')
      console.dir(this.positions)
    })
  }

  selectTab = (tab: any) => {
    this.selectedTab = tab
  }

  setFileOutputTemplateOption = (option) => {
    console.dir(option)
  }

  toggleIncludePreviouslyExportedUserData = () => {
    this.settings.includePreviouslyExportedUserData = !this.settings.includePreviouslyExportedUserData
  }

  toggleGroupByExportDate = () => {
    this.settings.groupByExportDate = !this.settings.groupByExportDate
  }

  updatePositions = (event: any) => {
    if (event.target.value === '') {
      return
    }
    const selectedValue = this.positions.find((item: any) => item.positionTitle === event.target.value);
    this.positioninput.nativeElement.value = '';
    const alreadySelected = this.selectedPositions.some(item => item.id === selectedValue.id)
    if (!alreadySelected) {
      this.selectedPositions.push(selectedValue)
    }
    this.positioninput.nativeElement.value = '';
  }

  removePosition = (position: any) => {
    this.selectedPositions = this.selectedPositions.filter((item: any) => item.positionTitle !== position.positionTitle)
  }

  resetToDefault = () => {
    console.log('reset to default')
  }

  closeDialog = () => {
    this.dialog.closeAll()
  }

  // This is to save changes while in edit mode
  saveChanges = () => {}

}
