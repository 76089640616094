import { Component } from '@angular/core';

@Component({
  selector: 'app-new-input',
  templateUrl: './new-input.component.html',
  styleUrls: [ './new-input.component.scss' ],
})
export class NewInputComponent {

}
