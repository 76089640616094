import {
  Component, EventEmitter, Input, OnInit,
  Output,
} from '@angular/core';
import { ContentContainerNavButtonGroup } from '../content-container/content-container.component';

@Component({
  selector: 'app-button-group',
  templateUrl: './button-group.component.html',
  styleUrls: [ './button-group.component.scss' ],
})
export class ButtonGroupComponent implements OnInit{
  @Input() buttonGroup: ContentContainerNavButtonGroup[] = [];
  @Input() tabSelected = '';
  @Output() navClicked = new EventEmitter<string>();

  ngOnInit(): void {
    if (this.tabSelected === '') {
      this.tabSelected = this.buttonGroup[0].name;
    }
  }

  handleNavClick(name: string) {
    this.tabSelected = name;
    this.navClicked.emit(name);
  }
}
