<div class="container">
  <span *ngIf="label" class="klabel-medium label">{{ label }}</span>
  <div class="select-container" [ngClass]="styleType">
    <select
      id="select"
      (change)="updateInput($event)"
      class="input"
      [value]="value"
      [disabled]="disabled"
    >
      <ng-container *ngIf="placeholder">
        <option disabled selected value="">{{ placeholder }}</option>
      </ng-container>
      <option
        *ngFor="let option of options"
        [selected]="value === option.value"
        value="{{ option.value }}"
      >
        {{ option.text }}
      </option>
    </select>
  </div>
  <span *ngIf="error" class="klabel-medium error">{{ error }}</span>
</div>
