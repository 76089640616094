import {
  Component, OnDestroy, OnInit, isDevMode,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from './services/auth.service';
import { LoadingSpinnerService } from './services/loading-spinner.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.scss' ],
})
export class AppComponent implements OnInit, OnDestroy {
  loggedInSubscription: Subscription;
  loadingSubscription: Subscription;
  mobileNavIsOpen = false;
  loggedIn$: any = false;
  isLoading = true;

  constructor(private authService: AuthService,
    private loadingService: LoadingSpinnerService) { }

  ngOnInit() {
    this.loggedInSubscription = this.authService.loggedIn$
      .subscribe((loggedInStatus: boolean) => {
        this.loggedIn$ = loggedInStatus
      })
    this. loadingSubscription = this.loadingService.isLoading$.subscribe((isLoading: boolean) => {
      this.isLoading = isLoading
    })

    if (!isDevMode()) {
      // console.log = () => { };
    }

    this.authService.autoLogin()
    console.log(`
    __           __
    |  |--.---.-.|  |.-----.---.-.
    |    <|  _  ||  ||  _  |  _  |
    |__|__|___._||__||   __|___._|
                     |__|
  `);
  }


  ngOnDestroy(): void {
    this.loggedInSubscription.unsubscribe()
    this.loadingSubscription.unsubscribe()
  }
}
