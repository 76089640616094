import {
  Component, EventEmitter, Input, OnInit, Output,
} from '@angular/core';
import { SelectGroup } from 'src/app/components/_design-components/form-components/form-select-group/form-select-group.component';

export type ReportCourseListFilterParams = {
  showChanged?: boolean,
  showUnchanged?: boolean,
  showSpecial?: boolean,

  dateRangeStart?: Date | null,
  dateRangeEnd?: Date | null,

  requirementIds?: number[],
  categoryIds?: number[],
  tagIds?: number[],
}

@Component({
  selector: 'app-select-courses-filter',
  templateUrl: './select-courses-filter.component.html',
  styleUrls: [ './select-courses-filter.component.scss' ],
})
export class SelectCoursesFilterComponent  {
  @Input() displayModal = true;
  @Output() closeModal = new EventEmitter<ReportCourseListFilterParams>();

  filterOptions: ReportCourseListFilterParams = {
    showChanged: true,
    showUnchanged: true,
    showSpecial: false,
    dateRangeStart: null,
    dateRangeEnd: null,
    requirementIds: [],
    categoryIds: [],
    tagIds: [],
  };

  selectOptions: SelectGroup = {
    includeSelectAll: true,
    selectAllLabel: 'Show All',
    indentOptions: false,
    options: [
      {
        name: 'showSpecial',
        display: 'Show special requests (blue)',
        value: false,
      },
      {
        name: 'showChanged',
        display: 'Show changed since last export (yellow)',
        value: true,
      },
      {
        name: 'showUnchanged',
        display: 'Show unchanged since last export (grey)',
        value: true,
      },
    ],
  }

  handleSelectOptionsChange() {
    console.log('changed', this.selectOptions.options
      .map(option => [ option.name, option.value ]));
  }


  closeFilterModal() {
    this.closeModal.emit();
  }
}
