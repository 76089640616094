<div class="nav-container">
  <div
    class="nav-option"
    *ngFor="let nav of buttonGroup"
    [ngClass]="{
      selected: tabSelected === nav.name
    }"
    (click)="handleNavClick(nav.name)"
  >
    <div class="nav-content">
      <p>
        {{ nav.display }}
      </p>
    </div>
  </div>
</div>
