import {
  Component, ElementRef, HostListener, Input, OnChanges,
  OnDestroy,
  OnInit,
} from '@angular/core';
import gsap from 'gsap';

@Component({
  selector: 'app-pop-up-menu',
  templateUrl: './pop-up-menu.component.html',
  styleUrls: [ './pop-up-menu.component.scss' ],
})
export class PopUpMenuComponent implements OnInit, OnDestroy{
  @Input() open = false;
  @Input() defaultPosition: 'top' | 'bottom' | 'left' | 'right' = 'top';
  @Input() renderOnSide = false;
  @Input() parentId = ''
  @Input() padding = 4;

  @HostListener('window:click', [ '$event' ]) onClick(event: MouseEvent) {
    console.log('running host listener')
    this.open = false;
    this.animateDivOut();
  }

  stats: ReturnType<typeof PopUpMenuComponent.prototype.getElements>;

  isDisplayed = false;

  movedUp = false;
  movedLeft = false;

  closeScale = 0.8;
  animationDuration = 0.15;
  defaultEase = 'power1.inOut';

  constructor(private viewRef: ElementRef) {
  }

  ngOnInit() {
    if (!this.parentId) {
      throw new Error('ParentId is required for this component');
    }

    setTimeout(() => {
      this.placeDivOffScreen();
    });
  }

  async ngOnChanges(changes: any) {
    // if open check position
    const isClosing = changes.open && !changes.open.currentValue && changes.open.previousValue;
    const curTop = this.getGsapStyles();
    const isOffScreen = curTop && curTop < 0;
    console.log('🚀 - this.open:', this.open);
    if (this.open || isOffScreen) {
      setTimeout(() => {
        this.placeDivOffScreen();
        this.setPosition();
        this.moveDiv();
      }, 0);
    } else if (!this.open && !isOffScreen) {
      this.open = true;
      await this.animateDivOut();
    }
  }

  placeDivOffScreen() {
    console.log('placing off screen');
    const popUpContainer = document.getElementById('pop-up-container') as HTMLElement;
    const body = document.body;
    body.appendChild(popUpContainer);
    popUpContainer.style.top = '-9999px';
    popUpContainer.style.left = '-9999px';

    this.viewRef.nativeElement.remove();
  }

  getGsapStyles() {
    const popUpContainer = document.getElementById('pop-up-container') as HTMLElement;

    const curTop = gsap.getProperty(popUpContainer, 'top');
    return Number(curTop);
  }

  getElements() {
    console.log('running get elements');
    const popUpContainer = document.getElementById('pop-up-container') as HTMLElement;

    const parentDiv = document.getElementById(this.parentId) as HTMLElement;

    const popUpRect = popUpContainer.getBoundingClientRect();
    const parentRect = parentDiv.getBoundingClientRect();

    const parentTop = parentRect.top;
    const parentLeft = parentRect.left;
    const parentBottom = parentRect.bottom;
    const parentRight = parentRect.right;

    const parentWidth = parentRect.width;


    const popUpHeight = popUpRect.height;
    const popUpWidth = popUpRect.width;

    const remainingTopHeight = parentTop;
    const remainingLeftWidth = parentLeft;
    const remainingBottomHeight = window.innerHeight - parentBottom;
    const remainingRightWidth = window.innerWidth - parentRight;

    let moveUp = remainingBottomHeight < popUpHeight;
    let moveLeft = remainingRightWidth < popUpWidth;
    const moveRight = remainingLeftWidth < popUpWidth;
    const moveDown = remainingTopHeight < popUpHeight;

    const defaultPos = this.defaultPosition;
    const defaultTop = defaultPos === 'top';
    const defaultLeft = defaultPos === 'left';

    moveUp = ((defaultTop && !moveDown) || moveUp);
    moveLeft = ((defaultLeft && !moveRight) || moveLeft);

    const stats = {
      popUpContainer, parentDiv, parentTop, parentLeft, parentBottom, parentRight, parentWidth, popUpHeight, popUpWidth, remainingBottomHeight, remainingRightWidth, moveUp, moveLeft, moveDown, moveRight,
    };

    this.stats = stats;
    return stats;
  }

  moveDiv() {
    const {
      popUpContainer, parentTop, parentLeft, parentBottom, parentRight, popUpHeight, popUpWidth,
      moveUp, moveLeft,
    } = this.stats;

    let adjustedTop = 0;
    let adjustedLeft = 0;

    if (this.renderOnSide) {
      adjustedTop = moveUp ? parentBottom - popUpHeight : parentTop;

      adjustedLeft = moveLeft ? parentLeft - popUpWidth : parentRight;
      adjustedLeft += moveLeft ? -this.padding : this.padding;
    } else {
      adjustedTop = moveUp ? parentTop - popUpHeight: parentBottom;
      adjustedTop += moveUp ? -this.padding : this.padding;

      adjustedLeft = moveLeft ?  parentRight - popUpWidth : parentLeft;
    }

    popUpContainer.style.top = `${adjustedTop}px`;
    popUpContainer.style.left = `${adjustedLeft}px`;
    this.animateDivIn();
  }

  getOrigin() {
    const { moveUp, moveLeft } = this.stats;

    const originTopBottom = moveUp ? '50%' : '50%';
    const originLeftRight = moveLeft ? '50%' : '50%';

    return `${originTopBottom} ${originLeftRight}`;
  }

  animateDivIn() {
    const { popUpContainer } = this.stats;
    const origin = this.getOrigin();

    gsap.fromTo(
      popUpContainer,
      {
        transformOrigin: origin,
        duration: this.animationDuration,
        scale: this.closeScale,
        ease: this.defaultEase,
        autoAlpha: 0,
      },
      {
        transformOrigin: origin,
        duration: this.animationDuration,
        ease: this.defaultEase,
        autoAlpha: 1,
        scale: 1,
        onComplete: () => {
          this.isDisplayed = true;
        },
      },
    );
  }

  animateDivOut() {
    if (!this.stats) return;

    const { popUpContainer } = this.stats;
    const origin = this.getOrigin();

    gsap.to(popUpContainer, {
      transformOrigin: origin,
      duration: this.animationDuration,
      scale: this.closeScale,
      ease: this.defaultEase,
      autoAlpha: 0,
      onComplete: () => {
        this.open = false;
        this.isDisplayed = false;

        gsap.set(popUpContainer, {
          top: '-9999px', left: '-9999px', scale: 1,
        });
      },
    });
  }

  setPosition() {
    this.getElements();
    return;
  }

  ngOnDestroy() {
    console.log('destroyed')
  }
}
