<div class="manage-courses">
  <span class="ktitle-medium title">Where would you like to go?</span>
  <div class="cards" *ngIf="cards.length">
    <a class="card" *ngFor="let card of cards" routerLink="{{ card.link }}">
      <mat-icon fontSet="material-icons-round" class="icon">{{
        card.icon
      }}</mat-icon>
      <span class="klabel-huge card-title">{{ card.title }}</span>
    </a>
  </div>
</div>
