import {
  Component, Input, OnInit,
} from '@angular/core';

export interface StepperWizardStep {
  displayName: string;
  name: string;
  completed: boolean;
}

@Component({
  selector: 'app-new-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: [ './stepper.component.scss' ],
})
export class NewStepperComponent implements OnInit {
  @Input() steps: StepperWizardStep[] = [];
  @Input() activeStep = '';
  stepLimit = 6;

  ngOnInit(): void {
    if (this.steps.length > this.stepLimit) {
      throw new Error(`Stepper component only supports up to ${this.stepLimit} steps`);
    }

    if (this.steps.length === 0) {
      throw new Error('Stepper component must have at least one step');
    }

    if (!this.activeStep) {
      this.activeStep = this.steps[0].name;
    }
  }
}
