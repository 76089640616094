<div class="pagination" [ngClass]="{'useOldTableStyling': useOldTableStyling}">
    <div class="tabs" *ngIf="pager.pages && pager.pages.length">
      <div class="tabs numbers-arrows">
        <div class="tabs arrows">
          <app-button
            *ngIf="pager.currentPage > 1"
            (click)="setPage(1, 'first')"
            [icon]="{ materialIcon: 'first_page' }"
            [class]="'paginationButtonArrows'"
            [disabled]="pager.currentPage === 1"
            [disableBorder]="false"
          >
            ></app-button
          >
          <app-button
            *ngIf="pager.currentPage > 1"
            (click)="setPage(pager.currentPage - 1, 'prev')"
            [icon]="{ materialIcon: 'chevron_left' }"
            [class]="'paginationButtonArrows'"
            [disabled]="pager.currentPage === 1"
            [disableBorder]="false"
          ></app-button>
        </div>
        <div class="tabs numbers" *ngIf="pager.totalPages > 1">
          <app-button
            *ngFor="let page of pager.pages"
            [disableBorder]="false"
            [text]="'' + page"
            (click)="tab(page)"
            [class]="
              page === (tableData?.curPage || tableData?.meta?.curPage)
                ? 'paginationButtonNumbersActive'
                : 'paginationButtonNumbers'
            "
          >
            {{ page }}
          </app-button>
        </div>
        <div class="tabs arrows">
          <app-button
            *ngIf="pager.totalPages > 1"
            [disabled]="pager.currentPage === pager.totalPages"
            (click)="setPage(pager.currentPage + 1, 'next')"
            [icon]="{ materialIcon: 'chevron_right' }"
            [class]="'paginationButtonArrows'"
            [disableBorder]="false"
          ></app-button>
          <app-button
            *ngIf="pager.totalPages > 1"
            [disabled]="pager.currentPage === pager.totalPages"
            (click)="setPage(pager.totalPages, 'last')"
            [icon]="{ materialIcon: 'last_page' }"
            [class]="'paginationButtonArrows'"
            [disableBorder]="false"
          ></app-button>
        </div>
      </div>
      <div class="tabs pager">
        <div class="dropdown">
          <app-button
            [text]="selectedItemsPerPage + ' Items per page'"
            (click)="toggleDropdown()"
            [icon]="{ materialIcon: 'expand_more' }"
            [class]="'paginationReverse'"
          ></app-button>
          <div class="dropdown-content" [class.show]="isDropdownOpen">
            <a (click)="selectItemsPerPage(25)">25</a>
            <a (click)="selectItemsPerPage(50)">50</a>
            <a (click)="selectItemsPerPage(100)">100</a>
            <a (click)="selectItemsPerPage(500)">500</a>
          </div>
        </div>

        <div class="tabs counter">
          <div class="itemcount">Total: {{ tableData?.totalItems || tableData?.meta?.totalItems}} items</div>
        </div>
      </div>
    </div>
  </div>