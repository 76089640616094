import {
  Component, Input, OnInit,
} from '@angular/core';

export type IconComponentStyles = 'filled' | 'outlined' | 'round' | 'sharp';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: [ './icon.component.scss' ],
})
export class IconComponent implements OnInit {
  @Input() fontSize: number | string;
  @Input() iconStyle: IconComponentStyles = 'outlined';

  defaultFontSize = 22;
  scaleBy = 1;

  ngOnInit() {
    const requestedFontSize = parseInt(this.fontSize as any, 10);

    if (!isNaN(requestedFontSize)) {
      this.scaleBy = requestedFontSize / this.defaultFontSize;
    }
  }

  getFontSet() {
    const defaultFont = 'material-icons';

    const fontSet = this.iconStyle;

    if (fontSet !== 'filled') {
      return `${defaultFont}-${fontSet}`;
    }

    return defaultFont;
  }
}
