import {
  Component, Inject, EventEmitter, Output, OnInit, OnDestroy, Input, ViewChild,
} from '@angular/core';
import {
  MAT_DIALOG_DATA, MatDialog, MatDialogRef,
} from '@angular/material/dialog';
import { CoursesService } from 'src/app/services/courses.service';
import { EntityService } from 'src/app/services/entity.service';

@Component({
  selector: 'app-push-to-group',
  templateUrl: './push-to-group.component.html',
  styleUrls: [ './push-to-group.component.scss' ],
})
export class PushToGroupComponent implements OnInit, OnDestroy {
  @ViewChild('buildingsinput') buildingsinput: any;
  @ViewChild('positionsinput') positionsinput: any;
  @Output() backToPrimaryPushList = new EventEmitter<object>();
  @Input() completeCourseSessionDetails: any;
  pushGroups: any;
  pushDefSubscription: any;
  coursePushSubscription: any;
  coursePushPackage: any = {
    buildings: [], requirement: {}, positions: [],
  }
  isLoading = false;

  // This is the initial setup for group push set as individual-building
  selectedPushGroup: any;
  currentScreen = 'index'
  screenArray = [
    'index', 'select-group', 'select-requirement', 'select-buildings', 'send-message', 'complete',
  ]
  // this is seperate from the screenArray because not all screens are a step in the step wizard
  steps = [
    { completed: false, text: 'Select Push Parameters' },
    { completed: false, text: 'Select Requirement' },
    { completed: false, text: 'Select Buildings' },
    { completed: false, text: 'Send Message' },
  ]
  selectedStep = this.steps[0]
  formattedCourseRequirements: any;
  entitySubscription: any;
  buildings: any;
  positions: any;
  error: any;
  coursePushes: any;
  pushesSubscription: any;

  constructor(
    private coursesService: CoursesService,
    private entityService: EntityService,
    public dialogRef: MatDialogRef<PushToGroupComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit() {
    // this is where we get the push group definition from express
    this.coursesService.getCoursePushGroups()
    this.pushDefSubscription = this.coursesService.pushDefinitions$.subscribe((pushDefinitions: any) => {
      if (!pushDefinitions) return;
      this.pushGroups = pushDefinitions?.rows;
      this?.selectedPushGroup || !this?.pushGroups ? null : this.selectedPushGroup = this?.pushGroups[0].kalpaIdentifier;
    })
    // this is where we format the course requirements for our select dropdown
    this.formattedCourseRequirements = this.completeCourseSessionDetails?.Course?.Requirements?.map((requirement: any) => {
      return {
        text: requirement.requirement,
        value: requirement.requirementId,
      }
    })
    this.formattedCourseRequirements.unshift({ value: 'portfolio', text: 'Portfolio/Unassigned' });
    // in order to get all buildings and positions, we have to call it as an additinonal parameter from the entity
    this.entityService.getEntity('Buildings, Positions')
    this.entitySubscription = this.entityService.entityData$.subscribe((entityData: any) => {
      this.buildings = entityData?.Buildings;
      this.positions = entityData?.Positions;
    })
    // this is where we call for the index of course item pushes
    this.coursesService.getCoursePushes({ 'courseItemId': this.completeCourseSessionDetails.id,  'includedAssociations': 'Requirement, Building, Position, Push' })
    this.pushesSubscription = this.coursesService.pushes$.subscribe((coursePushes: any) => {
      if (!coursePushes) return;
      this.coursePushes = coursePushes?.rows;
    })
  }

  checkDisabled = () => {
    switch (this.currentScreen) {
    case 'index':
      return false;
    case 'select-group':
      return false;
    case 'select-requirement':
      return !this.coursePushPackage?.requirement?.requirement;
    case 'select-buildings':
      return !(this.coursePushPackage?.buildings?.length > 0);
    case 'send-message':
      return false;
    case 'complete':
      return false;
    default:
      return false;
    }
  }

  updateCoursePushPackageParameter = (event: any, parameter: any) => {
    let newValue;
    if(parameter === 'requirement') {
      const req = this.completeCourseSessionDetails?.Course?.Requirements?.find((requirement: any) => {
        return requirement.requirementId == event;
      });
      newValue = { id: req?.requirementId, requirement: req?.requirement };
      event === 'portfolio' ? newValue = { id: 'portfolio', requirement: 'Portfolio/Unassigned' } : null;
    }
    if(parameter === 'buildings') {
      // if the building is already in the list, clear the form and return
      if(this.coursePushPackage[parameter].find((building: any) => building.buildingName === event.target.value)) {
        this.buildingsinput.nativeElement.value = '';
        return
      }
      const newBuilding = this?.buildings?.find((building: any) => building.buildingName === event.target.value);
      this.buildingsinput.nativeElement.value = '';
      newValue = [ ...this.coursePushPackage[parameter], newBuilding ];
    }
    if(parameter === 'positions') {
      // if the building is already in the list, clear the form and return
      if(this.coursePushPackage[parameter].find((position: any) => position.positionTitle === event.target.value)) {
        this.buildingsinput.nativeElement.value = '';
        return
      }
      const newPosition = this?.positions?.find((position: any) => position.positionTitle === event.target.value);
      this.positionsinput.nativeElement.value = '';
      newValue = [ ...this.coursePushPackage[parameter], newPosition ];
    }

    const newCoursePushPackage = { ...this.coursePushPackage, [parameter]: newValue }
    this.coursePushPackage = newCoursePushPackage;
  }

  removeBuilding = (building: any) => {
    this.coursePushPackage.buildings = this.coursePushPackage.buildings.filter((item: any) => item !== building);
  }

  removePosition = (position: any) => {
    this.coursePushPackage.positions = this.coursePushPackage.positions.filter((item: any) => item !== position);
  }

  updateWizard = () => {
    switch (this.selectedPushGroup) {
    case 'COURSE_PUSH_TO_BUILDING_USERS':
      this.screenArray = [
        'index', 'select-group', 'select-requirement', 'select-buildings', 'send-message', 'complete',
      ]
      this.steps = [
        { completed: false, text: 'Select Push Parameters' },
        { completed: false, text: 'Select Requirement' },
        { completed: false, text: 'Select Buildings' },
        { completed: false, text: 'Send Message' },
      ]
      this.selectedStep = this.steps[0]
      break;
    case 'COURSE_PUSH_TO_ENTITY_USERS':
      this.screenArray = [
        'index', 'select-group', 'select-requirement', 'send-message', 'complete',
      ]
      this.steps = [
        { completed: false, text: 'Select Push Parameters' },
        { completed: false, text: 'Select Requirement' },
        { completed: false, text: 'Send Message' },
      ]
      this.selectedStep = this.steps[0]
      break;
    case 'COURSE_PUSH_TO_POSITION_USERS':
      this.screenArray = [
        'index', 'select-group', 'select-requirement', 'select-positions', 'send-message', 'complete',
      ]
      this.steps = [
        { completed: false, text: 'Select Push Parameters' },
        { completed: false, text: 'Select Requirement' },
        { completed: false, text: 'Select Positions' },
        { completed: false, text: 'Send Message' },
      ]
      this.selectedStep = this.steps[0]
      break;
    case 'COURSE_PUSH_TO_POSITION_BUILDING_USERS':
      this.screenArray = [
        'index', 'select-group', 'select-requirement', 'select-buildings', 'select-positions', 'send-message', 'complete',
      ]
      this.steps = [
        { completed: false, text: 'Select Push Parameters' },
        { completed: false, text: 'Select Requirement' },
        { completed: false, text: 'Select Buildings' },
        { completed: false, text: 'Select Positions' },
        { completed: false, text: 'Send Message' },
      ]
      this.selectedStep = this.steps[0]
      break;
    default:
      this.screenArray = [
        'index', 'select-group', 'select-requirement', 'select-buildings', 'send-message', 'complete',
      ]
      this.steps = [
        { completed: false, text: 'Select Push Parameters' },
        { completed: false, text: 'Select Requirement' },
        { completed: false, text: 'Select Buildings' },
        { completed: false, text: 'Send Message' },
      ]
      this.selectedStep = this.steps[0]
      break;
    }
  }

  advanceScreen = () => {
    if(this.checkDisabled()) {
      return
    }
    // here we advance the step wizard on every screen except the index screen
    if (this.currentScreen !== 'index') {
      const currentIndex = this.steps.findIndex((step: any) => step === this.selectedStep)
      this.steps[currentIndex] ? this.steps[currentIndex].completed = true : null;
      this.selectedStep = this.steps[currentIndex + 1]
    }
    // here we reset the step wizard if we are going back to the index screen
    if(this.currentScreen === 'index') {
      this.steps.forEach((step: any) => step.completed = false)
      this.coursePushPackage = {
        buildings: [], requirement: {}, positions: [],
      }
      this.selectedPushGroup = this?.pushGroups[0].kalpaIdentifier;
    }
    // here we advance the screen, wizard or not
    const currentIndex = this.screenArray.indexOf(this.currentScreen);
    this.screenArray[currentIndex + 1] ? this.currentScreen = this.screenArray[currentIndex + 1] : null;
  }

  goBack = () => {
    if (this.currentScreen !== 'index' && this.currentScreen !== 'select-group') {
      const currentIndex = this.steps.findIndex((step: any) => step === this.selectedStep)
      this.selectedStep = this.steps[currentIndex - 1]
    }
    const currentIndex = this.screenArray.indexOf(this.currentScreen);
    this.currentScreen = this.screenArray[currentIndex - 1];
  }

  backToIndex = () => {
    this.currentScreen = 'index';
    this.error = null;
  }

  backToPrimaryPushIndex = () => {
    this.error = null;
    this.backToPrimaryPushList.emit();
  }

  submitPush = () => {
    const formattedPositionIds = this.coursePushPackage.positions.map((position: any) => parseFloat(position.id));
    const formattedBuildingIds = this.coursePushPackage.buildings.map((building: any) => parseFloat(building.id));
    const formattedPushPackage: any = {
      'requirementId': this.coursePushPackage.requirement.id === 'portfolio' ? null : parseFloat(this.coursePushPackage.requirement.id), // REQ
      'courseId': parseFloat(this.completeCourseSessionDetails.Course.id), // REQ
      'courseItemId': parseFloat(this.completeCourseSessionDetails.id), // REQ
      'pushKid': this.selectedPushGroup,
      'buildingIds': formattedBuildingIds,
      'positionIds': formattedPositionIds,
      'messageSubject': this.coursePushPackage.messageTitle,
      'messageBody': this.coursePushPackage.messageContent,
    }
    // remove any null values from the push package
    Object.keys(formattedPushPackage).forEach((key) => {
      const value = formattedPushPackage[key];
      const isFalsey = value === null || value === undefined || value === '';
      const isEmptyArray = Array.isArray(value) && value.length === 0;

      if (isFalsey || isEmptyArray) {
        delete formattedPushPackage[key];
      }
    });

    this.isLoading = true;
    this.coursePushSubscription = this.coursesService.pushCourse(formattedPushPackage).subscribe({
      next: (response: any) => {
        this.isLoading = false;
        this.coursePushPackage = {
          buildings: [], requirement: {}, positions: [],
        }
        this.advanceScreen();
        this.coursesService.getCoursePushes({ 'courseItemId': this.completeCourseSessionDetails.id,  'includedAssociations': 'Requirement, Building, Position, Push' })
      },
      error: (error: any) => {
        console.log('error', error);
        this.advanceScreen();
        this.error = error;
      },
    })
  }

  closeModal = () => {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.pushDefSubscription.unsubscribe();
    this.entitySubscription.unsubscribe();
    this.coursePushSubscription?.unsubscribe();
    this.pushesSubscription.unsubscribe();
  }

}
