import { Component } from '@angular/core';
import gsap from 'gsap';

@Component({
  selector: 'app-form-date-picker',
  templateUrl: './form-date-picker.component.html',
  styleUrls: [ './form-date-picker.component.scss' ],
})
export class FormDatePickerComponent {
  showCalendar = false;



  toggleCalendar() {
    this.showCalendar = !this.showCalendar;

    if (!this.showCalendar) {
      return;
    }

    // setTimeout(this.setCalendarPosition, 0)
  }

  // setCalendarPosition() {
  //   const floatingCalendar = document.getElementById('floating-calendar') as HTMLElement;
  //   const closestContentDiv = floatingCalendar?.closest('.modal-container') as HTMLElement || window.document.body;
  //   const inputDiv = document.getElementById('date-picker-input') as HTMLElement;

  //   const floatingCalRect = floatingCalendar.getBoundingClientRect();
  //   const contentDivRect = closestContentDiv.getBoundingClientRect();
  //   const inputDivRect = inputDiv.getBoundingClientRect();

  //   const floatingMaxHeight = contentDivRect.height - (floatingCalRect.top - contentDivRect.top);
  //   floatingCalendar.style.maxHeight = `${floatingMaxHeight}px`;
  //   const remainingSpace = contentDivRect.bottom - floatingCalRect.bottom;



  //   if (remainingSpace < 0) {
  //     const deltaY = inputDivRect.height + floatingCalRect.height + 8;
  //     gsap.set(floatingCalendar, { y: -deltaY });
  //   }

  //   // const containingDiv
  // }
}
