<div class="header-row" ngProjectAs="[header]">
  <div class="left header-side">
    <h4>Select Requirements</h4>
  </div>
  <div class="right header-side">
    <app-new-button
      (clickHandler)="previousStep()"
      leftIcon="arrow_back"
      size="sm"
      color="bare"
      customPadding="12px 16px"
      >Go Back</app-new-button
    >
    <app-new-button
      (clickHandler)="nextStep()"
      rightIcon="arrow_forward"
      size="sm"
      color="green"
      customPadding="12px 16px"
      [disabled]="selectedRequirements.length === 0"
    >
      Continue to next step
    </app-new-button>
  </div>
</div>

<ng-container>
  <div class="select-requirements-section">
    <div class="section-headings">
      <div class="select-all-requirements">
        <input type="checkbox"
          [checked]="areAllRequirementsSelected()"
          (change)="toggleAllRequirements()"
        />
        <span class="all-requirements-checkbox-label">Requirements</span>
      </div>
      <div class="include-in-report">
        <span class="include-in-report-label">Include in Report</span>
      </div>
    </div>
    <div class="list-and-chips-container">
      <div class="requirements-list">
        <div *ngFor="let requirement of requirements" class="requirement-checkbox">
          <input type="checkbox"
            [checked]="requirement.checked"
            (change)="handleRequirementSelected(requirement)"
          />
          <span>{{ requirement.name }}</span>
        </div>
      </div>
      <div class="requirements-chips-container">
        <div class="top">
          <span class="requirements-chips-title">Requirements Selected</span>
          <!-- <div class="remember-selections-container">
            <input type="checkbox"
              [checked]="rememberSelections"
              (change)="toggleRememberSelections()"
            />
            <span>Remember Selections</span>
          </div> -->
        </div>
        <div class="requirements-chips">
          <ng-container *ngFor="let requirement of requirements">
            <div class="chip" *ngIf="requirement.checked">
              <span>{{ requirement.name }}</span>
              <mat-icon fontSet="material-icons-round" class="close-icon" (click)="handleRequirementSelected(requirement)">close</mat-icon>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-container>
