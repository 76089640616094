<app-content-container
  title="State Documentation Export"
  [titleButtonGroup]="titleButtons"
  (buttonClicked)="handleButtonClicked($event)"
>
  <div class="header" fixedHeader>
    <app-new-stepper
      [steps]="wizardSteps"
      [activeStep]="activeStep"
    ></app-new-stepper>
    <ng-content select="[header]"></ng-content>
  </div>

  <app-select-courses (setSelectedCoursesEvent)="setSelectedCourses($event)" (changeStepEvent)="goToStep($event)" *ngIf="activeStep === 'select-courses'" />
  <app-select-requirements [courses]="selectedCourses" (setSelectedRequirementsEvent)="setSelectedRequirements($event)" (changeStepEvent)="goToStep($event)" *ngIf="activeStep === 'select-requirements'" />
  <app-finish-export [courses]="selectedCourses" [requirements]="selectedRequirements" (showExportSettingsEvent)="openExportSettingsModal()" (changeStepEvent)="goToStep($event)" *ngIf="activeStep === 'finish-export'" />
</app-content-container>
