<div class="option">
  <mat-checkbox
    class="checkbox"
    [disabled]="disabled"
    [checked]="value"
    [indeterminate]="indeterminate !== undefined && indeterminate"
    (change)="toggleChanged()"
  />
  <p class="label">{{ display }}</p>
</div>
