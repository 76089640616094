<div *ngIf="cellIsUserInfo(cell)" class="user-info">
  <p>{{ cell.fullName }}</p>
  not implemented
</div>

<div *ngIf="cellIsGeneralInfo(cell)" class="general">
  <p
    [ngClass]="{
      'main-text': true,
      'only-main': !cell.subText
    }"
    [style]="{
      'font-weight': cell.mainFontStyle || 'bold'
    }"
  >
    {{ cell.mainText }}
  </p>
  <p class="sub-text" *ngIf="cell.subText">{{ cell.subText }}</p>
</div>

<div
  *ngIf="cellIsStatusInfo(cell)"
  [ngClass]="['status', cell.statusType, cell.statusKid]"
>
  <p>
    {{ cell.statusDisplay.toLocaleUpperCase() }}
  </p>
</div>

<div *ngIf="cellIsSpecialInfo(cell)" class="special">
  <p>
    {{ cell.specialType }}
  </p>
  not implemented
</div>
<ng-template #unknown>Unknown data type</ng-template>
