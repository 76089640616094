import {
  Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import UserGenerator from 'src/app/classes/user.class';


export class ItemFullNode {
  requirement: string;
  id: number;
  parentId: any;
  topMostParentId: any;
  children: ItemFullNode[];
  Checked: boolean;
  trackingValue: number;
  error: any;
  label: any;
  nodeType: any;
}

export class ItemFlatNode {
  expandable: boolean;
  requirement: string;
  id: number;
  parentId: any;
  topMostParentId: any;
  level: number;
  Checked: boolean;
  trackingValue: number;
  error: any;
  label: any;
  nodeType: any;
}
@Component({
  selector: 'app-user-requirements',
  templateUrl: './user-requirements.component.html',
  styleUrls: [ './user-requirements.component.scss' ],
})

export class UserRequirementsComponent implements OnInit {
  @Output() updateUserObject = new EventEmitter<object>();
  @Output() stepBack = new EventEmitter<any>();
  @Input() isEditMode: any;
  @Input() permissions: any;
  @Input() user: UserGenerator;
  @Input() requirements: any;
  @Input() step: { text: string; completed: boolean; modified: boolean; }

  matches: ItemFullNode[];
  creditError: any;

  //Map from flat node to nested node. This helps us finding the nested node to be modified
  FlatNodeMap = new Map<ItemFlatNode, ItemFullNode>();

  //Map from nested node to flattened node. This helps us to keep the same object for selection
  nestedNodeMap = new Map<ItemFullNode, ItemFlatNode>();

  //A selected parent node to be inserted
  selectedParent: ItemFlatNode | null = null;

  //The selection for checklist
  checklistSelection = new SelectionModel<ItemFlatNode>(true /* multiple */);

  treeControl: FlatTreeControl<ItemFlatNode>;
  treeFlattener: MatTreeFlattener<ItemFullNode, ItemFlatNode>;
  dataSource: MatTreeFlatDataSource<ItemFullNode, ItemFlatNode>;


  constructor(public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.initializeTree();
    if (!this.requirements) return;

    this.dataSource.data = this.arrangeRequirementsBasedOnSelection();
    this.matches = [];
    this.processDataSource();
  }

  ngOnChanges(changes: SimpleChanges) {
    const requirements = changes['requirements'];

    if (requirements?.currentValue) {
      this.initializeTree();

      this.dataSource.data = this.arrangeRequirementsBasedOnSelection();
      this.matches = [];
      this.processDataSource();
    }
  }

  // #region Tree processing functions
  getLevel = (node: ItemFlatNode) => node.level;

  isExpandable = (node: ItemFlatNode) => node.expandable;

  getChildren = (node: ItemFullNode): ItemFullNode[] => node.children;

  hasChild = (_: number, _nodeData: ItemFlatNode) => _nodeData.expandable;

  hasNoContent = (_: number, _nodeData: ItemFlatNode) => _nodeData.requirement === '';

  // Transformer to convert nested node to flat node. Record the nodes in maps for later use.
  private _transformer = (node: ItemFullNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.id === node.id
      ? existingNode
      : new ItemFlatNode();
    flatNode.id = node.id;
    flatNode.level = level;
    flatNode.requirement = node.requirement;
    flatNode.parentId = node.parentId;
    flatNode.topMostParentId = node.topMostParentId;
    flatNode.Checked = this.checkedChecker(node);
    flatNode.label = this.checkLabel(node);
    flatNode.nodeType = node.nodeType;
    flatNode.trackingValue = node.trackingValue;
    flatNode.expandable = !!node.children && node.children.length > 0;
    this.FlatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  }

  //Whether all the descendants of the node are selected
  descendantsAllSelected(node: ItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    if (descendants.length === 0) {
      return this.checklistSelection.isSelected(node);
    }
    const allSelected = descendants.every(child => this.checklistSelection.isSelected(child));
    if (allSelected) {
      this.checklistSelection.select(node);
    } else {
      this.checklistSelection.deselect(node);
    }
    return allSelected;

  }

  //Whether part of the descendants are selected
  descendantsPartiallySelected(node: ItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }


  //Toggle the to-do item selection. Select/deselect all the descendants node
  ItemSelectionToggle(node: ItemFlatNode): void {
    const descendants = this.treeControl.getDescendants(node);

    this.checklistSelection.toggle(node);

    const isSelected = this.checklistSelection.isSelected(node);

    isSelected
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    const requirementNodes = [ node, ...descendants ];

    this.user.toggleRequirements(requirementNodes, isSelected);
  }

  initializeTree = () => {
    this.treeControl = new FlatTreeControl<ItemFlatNode>(this.getLevel, this.isExpandable);


    this.treeFlattener = new MatTreeFlattener(
      this._transformer, this.getLevel, this.isExpandable, this.getChildren,
    );

    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    this.creditError = '';
  }
  processDataSource = () => {
    // sets this.matches
    this.setMatchedNodes();
    this.processMatches();

    this.treeControl.expandAll();
  }

  setMatchedNodes() {
    const data = this.dataSource.data;

    this.matches = data.filter((node) => {
      return this.checkedChecker(node);
    });
  }
  processMatches() {
    this.matches.forEach((matchedNode) => {
      this.processSingleMatch(matchedNode);
    })
  }
  processSingleMatch(matchedNode: ItemFullNode) {
    const nodeId = matchedNode.id;

    const matchingFlatNode = this.treeControl.dataNodes.find((node) => node.id === nodeId);

    if (!matchingFlatNode) {
      throw new Error('Matching flat node not found');
    }

    const hasChildren = matchedNode.children.length > 0;

    if (hasChildren) {
      const descendants = this.treeControl.getDescendants(matchingFlatNode);
      this.checklistSelection.select(matchingFlatNode);
      this.checklistSelection.select(...descendants)
    } else {
      this.checklistSelection.select(matchingFlatNode);
    }
  }
  // #endregion Tree processing functions


  //Update the credit hours
  updateCreditHours = (event: any, requirement: ItemFlatNode) => {
    this.creditError = '';
    requirement.error = '';
    requirement.trackingValue = event?.target.value;

    const trackingValue = event?.target.value;

    this.user.updateRequirementValue(requirement.id, trackingValue);

    if (requirement.nodeType === 'GROUP_PARENT') {
      const parentTrackingValue = event?.target.value;
      //const childArray = this.user.requirements.filter((req: any) => req.parentId === requirement.id);
      const childArray = this.treeControl.getDescendants(requirement);
      let childTrackingValue = 0;
      for (let i = 0; i < childArray.length; i++) {
        childArray[i].error = '';
        if (childArray[i].parentId === requirement.id) {
          childTrackingValue += Number(childArray[i].trackingValue);
        }
      }

      if (childTrackingValue > parentTrackingValue) {
        this.creditError = 'true';
        for (let x = 0; x < childArray.length; x++) {
          if (childArray[x].parentId === requirement.id) {
            childArray[x].error = 'Minimum hours cannot exceed parent requirement hours.';
          }
        }
        this.step.modified = true;
        return;
      }
    }
    else {
      if (requirement.parentId != null) {
        //Check that the total is not more than the parent
        const parentValue = this.treeControl.dataNodes.find((req: any) => req.id === requirement.parentId);
        const parentTrackingValue = parentValue?.trackingValue ?? 0;
        const childArray = this.treeControl.getDescendants(parentValue ?? requirement);

        let childTrackingValue = 0;
        for (let i = 0; i < childArray.length; i++) {
          if (childArray[i].parentId === parentValue?.id) {
            childTrackingValue += Number(childArray[i].trackingValue);
          }
        }

        if (childTrackingValue > parentTrackingValue) {
          this.creditError = 'true';
          requirement.error = 'Minimum hours cannot exceed parent requirement hours.';
          this.step.modified = true;
          return;
        }

        //checking if there are children and our new total is less than children
        if (requirement.nodeType === 'GROUP_NODE') {
          const parentTrackingValue = event?.target.value;
          const subchildArray = this.treeControl.getDescendants(requirement);
          let subchildTrackingValue = 0;
          for (let i = 0; i < subchildArray.length; i++) {
            subchildArray[i].error = '';
            if (subchildArray[i].parentId === requirement.id) {
              subchildTrackingValue += Number(subchildArray[i].trackingValue);
            }
          }
          if (subchildTrackingValue > parentTrackingValue) {
            this.creditError = 'true';
            for (let x = 0; x < subchildArray.length; x++) {
              if (subchildArray[x].parentId === requirement.id) {
                subchildArray[x].error = 'Minimum hours cannot exceed parent requirement hours.';
              }
            }
            this.step.modified = true;
            return;
          }

        }
      }
    }
  }

  stepBackward = () => {
    if (this.creditError === '') {
      this.stepBack.emit();
    }
  }

  closeDialog = () => {
    this.dialog.closeAll();
  }

  //Pass the values into the user
  updateUser = () => {
    if (this.creditError === '') {
      this.updateUserObject.emit(this.user);
    }
  }

  arrangeRequirementsBasedOnSelection = () => {
    if (this.user.pendingRequirementsArr.length > 0) {
      const { selectedRequirements, otherRequirements } =
      this.requirements.reduce((acc: any, req: any) => {
        const foundRequirement = this.user.pendingRequirementsArr.find((userReq: any) => userReq.id === req.id);

        if (!!foundRequirement) {
          acc.selectedRequirements.push(req);
        } else {
          acc.otherRequirements.push(req);
        }

        return acc;
      }, { selectedRequirements: [], otherRequirements: [] });

      return [ ...selectedRequirements, ...otherRequirements ];
    }

    return this.requirements;
  }

  //Check the checkbox if previously selected
  checkedChecker = (requirement: any) => {
    if (!requirement) return false;

    const requirementExists = this.user.checkRequirementIsSelected(requirement.id);

    return requirementExists;
  }

  //Update default trackingValue with previously selected value
  checkValue = (requirement: any) => {
    const foundRequirement = this.user?.requirementsArr?.find((req: any) => req.requirementId == requirement.id);
    const foundUserRequirement = this.user?.pendingRequirementsArr?.find((req: any) => req.id == requirement.id);

    if (foundUserRequirement) {
      const trackingValue = foundUserRequirement.trackingValue;
      return trackingValue;
    }

    if (foundRequirement) {
      const trackingValue = foundRequirement.requiredValue;
      return trackingValue;
    }
    return parseFloat(requirement?.trackingValue).toFixed(2);
  }

  //Adding Label to SINGLE and GROUP_PARENT only
  checkLabel = (requirement: any) => {
    if (requirement.nodeType === 'SINGLE' || requirement.nodeType === 'GROUP_PARENT') {
      return 'Hours Needed';
    }
    return '';
  }

  //Disable input if checkbox is not selected and in edit mode
  inputDisabled = (requirement: any) => {
    const isDisabled = this.checkDisabled(requirement);

    if (!isDisabled) {
      return !this.checkedChecker(requirement);
    }

    return true;
  }

  //Checking to see if user has permission to edit
  checkDisabled = (requirement: any) => {
    if (this.isEditMode && !this.permissions.USER_EDIT) {
      return true;
    }

    return false;
  }

  //TODO remove this function from html checkbox, when able to edit children nodes and pass to NODEJS
  checkboxDisabled = (requirement: any) => {
    if (requirement.parentId === null || requirement.parentId === undefined) {
      return false;
    }

    const isChecked = this.checkedChecker(requirement);


    return isChecked;
  }


}

