import {
  Component, EventEmitter, Input, OnChanges, OnInit, Output,
} from '@angular/core';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: [ './select.component.scss' ],
})
export class SelectComponent {
  @Input() label: string;
  @Input() options: { text: string; value?: any; }[] = [];
  @Input() value: any;
  @Input() error: string;
  @Input() color: string;
  @Input() placeholder: string;
  @Input() disabled: boolean;
  @Input() styleType: string;
  @Output() selectedValue = new EventEmitter<object>();
  localValue: any = '';

  constructor() { }

  updateInput = (event: any) => {
    this.selectedValue.emit(event.target.value);
  }
}
