import {
  Component, EventEmitter, Input, Output,
} from '@angular/core';
import { IconComponentStyles } from '../icon/icon.component';
import { IconOptions } from '@angular/material/icon';

@Component({
  selector: 'app-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: [ './search-filter.component.scss' ],
})
export class SearchFilterComponent {
  @Input() value = ''
  @Input() icon = 'search';
  @Input() iconStyle: IconComponentStyles = 'outlined';
  @Input() placeholder = 'Search';
  @Output() valueChange = new EventEmitter<string>();


  pendingValue = '';

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

  async handleValueChange(event: any) {
    const value = event?.target?.value;
    this.pendingValue = value;
    await this.delay(500);

    if (this.pendingValue !== value) {
      return;
    }
    this.valueChange.emit(value);
  }
}
