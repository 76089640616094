import {
  Component, OnDestroy, OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { FromMichiganGuard } from 'src/app/guards/from-michigan.guard';
import { PERMISSION_KIDS } from 'src/app/guards/permission.guard';
import { AuthService } from 'src/app/services/auth.service';

interface Card {
  title: string;
  icon: string;
  link: string;
  permission?: string;
  requireMI?: boolean;
}

@Component({
  selector: 'app-manage-courses',
  templateUrl: './manage-courses.component.html',
  styleUrls: [ './manage-courses.component.scss' ],
})
export class ManageCoursesComponent implements OnInit, OnDestroy {
  cardsList: Card[] = [
    {
      title: 'Course Catalog', icon: 'list', link: '/manage-courses/course-catalog',
    },
    {
      title: 'Validation',
      icon: 'checklist',
      link: '/manage-courses/validation',
      permission: PERMISSION_KIDS.VALIDATE_ATTENDANCE,
    },
    // {
    //   title: 'Course Calendar', icon: 'calendar_month', link: '',
    // },
    // {
    //   title: 'Course Requests',
    //   icon: 'post_add',
    //   link: '/manage-courses/course-requests',
    //   permission: PERMISSION_KIDS.COURSE_REQUESTS,
    // },
    // {
    //   title: 'Catalog Suggestions', icon: 'playlist_add_check', link: '',
    // },
    {
      title: 'State Documentation Export',
      icon: 'ios_share',
      link: '/manage-courses/documentation-export',
      permission: PERMISSION_KIDS.EXPORT_STATE_DOC,
      requireMI: true,
    },
  ]

  stateCode: string;

  authSubscription = this.authService.user$.subscribe((user: any) => {
    this.stateCode = user.Entity.stateCode;
  });

  cards: Card[] = this.cardsList;
  permissions: any;
  permissionsSubscription: any;

  constructor( private authService: AuthService, private router: Router) { }

  ngOnInit(): void {
    this.permissionsSubscription = this.authService.permissions$.subscribe((permissions: any) => {
      this.permissions = permissions;

      const cardsCopy = this.cardsList.map((card) => ({ ...card }));

      this.cards = cardsCopy.filter((card) => {
        let hasPermission = true;
        let fromMI = true;
        if (card?.permission) {
          hasPermission = this.permissions[card.permission];
        }

        if (card.requireMI) {
          fromMI = this.stateCode === 'MI';
        }

        return hasPermission && fromMI;
      });
    });

    if (this.cards.length === 1 && this.cards?.[0]?.link) {
      this.router.navigate([ this.cards[0].link ]);
    }
  }

  ngOnDestroy(): void {
    this.permissionsSubscription.unsubscribe();
  }

}
