import {
  Component, EventEmitter, Input, OnInit, Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import UserGenerator from 'src/app/classes/user.class';

@Component({
  selector: 'app-user-general-info',
  templateUrl: './user-general-info.component.html',
  styleUrls: [ './user-general-info.component.scss' ],
})
export class UserGeneralInfoComponent implements OnInit {
  @Output() updateUserObject = new EventEmitter<void>();
  @Output() stepBack = new EventEmitter<void>();
  @Input() isEditMode: any;
  @Input() permissions: any;
  @Input() user: UserGenerator;
  @Input() roles: any;
  @Input() step: { text: string; completed: boolean; modified: boolean; }
  @Input() isAdminUser: boolean;

  formattedRoles: any;


  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
    this.formatRoles();
  }

  ngOnChanges(changes: any) {
    if (changes.roles) {
      this.formatRoles();
    }
  }

  formatRoles = () => {
    if (!this.roles) return;

    this.formattedRoles = this.roles?.map((role: any) => {
      return {
        text: role.roleName,
        value: role.id,
      }
    });
  }

  updateUserParameter = (
    event: Event | any, parameter: keyof UserGenerator, type?: string,
  ) => {
    const eventValue = event?.target?.value;
    const value = eventValue !== undefined ? eventValue : event;

    this.user.updateValue(parameter, value);
    this.step.modified = true;
  }

  checkDisabled = () => {
    // This checks to make sure that email is an email and not anything else
    if(this.user.contactEmail) {
      const email = this.user.contactEmail;
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      if(!emailPattern.test(email)) {
        return true
      }
    }
    return false
  }

  updateUser = () => {
    if (this.user.firstName === '' ||
      this.user.lastName === '' ||
      this.user.contactEmail === '' ||
      this.user?.roleIds?.length === 0) {
      return
    }
    this.updateUserObject.emit();
  }

  closeDialog = () => {
    this.dialog.closeAll();
  }

}
