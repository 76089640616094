import {
  Component, Input, Output, EventEmitter, OnInit,
} from '@angular/core';

@Component({
  selector: 'app-select-requirements',
  templateUrl: './select-requirements.component.html',
  styleUrls: [ './select-requirements.component.scss' ],
})
export class SelectRequirementsComponent implements OnInit {
  @Input() isHeader?= false;
  @Input() courses = [];
  @Output() changeStepEvent = new EventEmitter();
  @Output() setSelectedRequirementsEvent = new EventEmitter();
  isLoading = false;
  requirements: any;
  rememberSelections = false;
  selectedRequirements = [];

  constructor() { }

  ngOnInit() {
    this.createListOfRequirements()
  }

  createListOfRequirements = () => {
    const reqs = []
    this.courses.forEach((courseObj: any) => {
      courseObj.Requirements.forEach((req) => {
        const alreadyIncluded = reqs.some(item => item.id === req.id)
        if (!alreadyIncluded) {
          reqs.push({
            id: req.id,
            name: req.requirement,
            checked: false,
          })
        }
      })
    })
    this.requirements = reqs
    this.getSelectedRequirements()
  }

  handleRequirementSelected = (requirement) => {
    requirement.checked = !requirement.checked
    this.getSelectedRequirements()
  }

  areAllRequirementsSelected = () => {
    return this.requirements.every((req) => req.checked)
  }

  toggleAllRequirements = () => {
    const areAllSelected = this.areAllRequirementsSelected()
    this.requirements.forEach((req) => req.checked = !areAllSelected)
    this.getSelectedRequirements()
  }

  getSelectedRequirements = () => {
    const selectedReqs = []
    this.requirements.forEach((req) => {
      if (req.checked) {
        selectedReqs.push(req)
      }
    })
    this.selectedRequirements = selectedReqs
    this.setSelectedRequirementsEvent.emit(this.selectedRequirements)
  }

  toggleRememberSelections = () => {
    this.rememberSelections = !this.rememberSelections
  }

  nextStep = () => {
    this.changeStepEvent.emit(2);
  }

  previousStep = () => {
    this.changeStepEvent.emit(0);
  }
}
