import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { ToastService } from './toast.service';
import { DialogRef } from '@angular/cdk/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { ReportCourseListFilterParams } from '../routes/manage-courses/components/state-documentation-export/tabs/select-courses/modal/select-courses-filter/select-courses-filter.component';

@Injectable({ providedIn: 'root' })
export class CoursesService {
  private _coursesData$ = new BehaviorSubject<any>(null);
  public readonly coursesData$ = this._coursesData$.asObservable();

  private _courseData$ = new BehaviorSubject<any>(null);
  public readonly courseData$ = this._courseData$.asObservable();

  private _courseSessionData$ = new BehaviorSubject<any>(null);
  public readonly courseSessionData$ = this._courseSessionData$.asObservable();

  private _pushDefinitions$ = new BehaviorSubject<any>(null);
  public readonly pushDefinitions$ = this._pushDefinitions$.asObservable();

  private _pushes$ = new BehaviorSubject<any>(null);
  public readonly pushes$ = this._pushes$.asObservable();

  _user$: any;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private toast: ToastService,
  ) {
    this.authService.user$.subscribe((user: any) => this._user$ = user)
  }

  registerForCourse = (registrationData: any) => {
    return this.http.post(
      `${environment.expressUrl}/courses/registration`,
      {
        'loginName': this._user$.loginName,
        'courseId': parseFloat(registrationData.courseId), // REQ
        'courseItemId': parseFloat(registrationData.courseItemId), // REQ
        'userId': parseFloat(this._user$.id), // REQ

        'adjustedValue': null,
        'requirementId': parseFloat(registrationData.requirementId) || null,

        // "pin": "12345"
      },
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    )
  }

  updateCourseRegistration = (registrationData: any) => {
    return this.http.put(
      `${environment.expressUrl}/courses/registration/${registrationData.id}`,
      {
        'loginName': this._user$.loginName,
        'currentStatusKid': registrationData.statusKid,
        'adjustedValue': null,
      },
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    )
  }

  // this updates the requirement that a users is applying thier course session
  updateUserCourseRequirement = (requirementData: any) => {
    return this.http.put(
      `${environment.expressUrl}/courses/registration/${requirementData.registrationId}`,
      {
        'loginName': requirementData.loginName,
        'currentStatusKid': requirementData.currentStatusKid, // Required, although should not change with this call
        'adjustedValue': null,
        'requirementId': parseFloat(requirementData.requirementId),
      },
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    )
  }

  getCourseRegistrations = (additionalParams?: any) => {
    this._coursesData$.next(null);
    const params = {
      'loginName': this._user$.loginName,
      'limit': 50,
      ...additionalParams,
    }
    return this.http.get(`${environment.expressUrl}/courses/registration`,
      {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params: params,
      })
      .subscribe({
        next: (response: any) => {
          this._coursesData$.next(response.data);
        },
        error: (error) => {
          this._coursesData$.next(error);
          console.log('error', error);
        },
      })
  }

  getPDPlanCourseRegistrations = (
    additionalParams?: any, limit = 25, offset = 0,
  ) => {
    this._coursesData$.next(null);
    const params = {

      'loginName': this._user$.loginName,
      'limit': limit,
      'offset': offset,

      ...additionalParams,
    }
    return this.http.get(`${environment.expressUrl}/courses/registration/pd-plan`,
      {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params: params,
      })
      .subscribe({
        next: (response: any) => {
          this._coursesData$.next(response.data);
        },
        error: (error) => {
          this._coursesData$.next(error);
          console.log('error', error);
        },
      })
  }

  getCourseSessions = (additionalParams?: any) => {
    this._coursesData$.next(null);
    const params = {
      'loginName': this._user$.loginName,
      'limit': 25,
      ...additionalParams,
    }
    return this.http.get(`${environment.expressUrl}/courses/items`,
      {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params: params,
      })
      .subscribe({
        next: (response: any) => {
          if (!response.data) {
            return this._coursesData$.next(null);
          }
          this._coursesData$.next(response.data);
        },
        error: (error: Error) => {
          this._coursesData$.next(error);
        },
      })
  }

  getCourse = (courseId: any, additionalParams?: any) => {
    const params = {
      'loginName': this._user$.loginName,
      ...additionalParams,
    }
    return this.http.get(`${environment.expressUrl}/courses/${courseId}`,
      {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params: params,
      })
      .subscribe({
        next: (response: any) => {
          this._courseData$.next(response.data);
        },
        error: (error) => {
          this._courseData$.next(error);
          console.log('error', error);
        },
      })
  }

  getPagination = (pageDirection: any) => {
    let links = '';
    let pagination = {
      curPage: 0, totalItems: 0, totalPages: 0,
    };
    this._coursesData$.subscribe(data => {
      links = data.links;
      pagination = data.pagination;
    });

    if (links[pageDirection] === '') {
      return;
    }
    return this.http.get(`${environment.expressUrl}/courses/items/` + links[pageDirection],
      { headers: { 'Content-Type': 'application/vnd.api+json' } })
      .subscribe({
        next: (response: any) => {
          this._coursesData$.next(response.data);
          console.log('response', response);
        },
        error: (error) => {
          console.log('error', error);
        },
      })
  }

  getCourseSession = (courseSessionId: any, additionalParams?: any) => {
    const params = {
      'loginName': this._user$.loginName,
      ...additionalParams,
    }
    return this.http.get(`${environment.expressUrl}/courses/items/${courseSessionId}`,
      {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params: params,
      })
      .subscribe({
        next: (response: any) => {
          this._courseSessionData$.next(response.data);
        },
        error: (error) => {
          this._courseSessionData$.next(error);
          console.log('error', error);
        },
      })
  }

  clearCourseSessionData = () => {
    this._courseSessionData$.next(null);
  }

  addCourse = (course: any) => {
    // TODO: add surveyId, stateRequirementId, hyperlink,
    return this.http.post(
      `${environment.expressUrl}/courses`,
      {
        'loginName': this._user$.loginName,
        ...course,
      },
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    )
  }

  cancelCourseSession = (courseSessionId: any, dialogRef: MatDialogRef<any>) => {
    const params = { 'loginName': this._user$.loginName }

    return this.http.delete(`${environment.expressUrl}/courses/items/${courseSessionId}`, {
      body: params,
      headers: { 'Content-Type': 'application/vnd.api+json' },
    })
      .subscribe({
        next: (response: any) => {
          this.toast.setToast({
            text: 'Course session canceled',
            type: 'success',
            dismissible: true,
            icon: true,
          })
          dialogRef.close('reload');
        },
        error: (error) => {
          this.toast.setToast({
            text: 'There was an error canceling the course session',
            type: 'error',
            dismissible: true,
            icon: true,
          })
          throw error;
        },
      })

  }

  // TODO: Course Create and Update - this does not update all fields, only the ones that are currently available in the api. update once all fields are available
  updateCourse = (course: any, courseId: any) => {
    return this.http.put(
      `${environment.expressUrl}/courses/${courseId}`,
      {
        'loginName': this._user$.loginName,
        ...course,
      },
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    )
  }

  // Course Push Stuff
  // This gets the definitions of the primary push groups
  getCoursePushGroups = () => {
    return this.http.get(`${environment.expressUrl}/pushes`,
      {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params: { 'loginName': this._user$.loginName },
      })
      .subscribe({
        next: (response: any) => {
          this._pushDefinitions$.next(response.data);
        },
        error: (error) => {
          console.log('error', error);
        },
      })
  }

  // This creates a course push
  pushCourse(pushPackage: any) {
    return this.http.post(
      `${environment.expressUrl}/courses/pushes`,
      {
        'loginName': this._user$.loginName,
        ...pushPackage,
      },
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    )
  }

  // This gets a list of pushes
  getCoursePushes = (params?: any) => {
    return this.http.get(`${environment.expressUrl}/courses/pushes`,
      {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params: {
          'loginName': this._user$.loginName,
          'limit': 25,
          ...params,
        },
      })
      .subscribe({
        next: (response: any) => {
          this._pushes$.next(response.data);
        },
        error: (error) => {
          if (error.status === 404) return;
          console.log('error', error);
        },
      })
  }

  // #region COURSE EXPORT REQUESTS
  getCoursesForExport = (params: {
      filterString?: string,
      limit?: number,
      offset?: number,
      yearId?: number,
      sortColumn?: string,
      sortDirection?: 'ASC' | 'DESC',
      filterData: ReportCourseListFilterParams,
      includedAssociations?: string,
    }) => {
    const {
      filterString,
      limit,
      offset,
      yearId,
      sortColumn,
      sortDirection,
      filterData,
      includedAssociations,
    } = params;

    const requestParams: any = { loginName: this._user$.loginName }

    if (filterString) {
      requestParams['filterString'] = filterString;
    }
    if (limit) {
      requestParams['limit'] = limit;
      requestParams['offset'] = offset || 0;
    }
    if (yearId) {
      requestParams['yearId'] = yearId;
    }

    if (includedAssociations) {
      requestParams['includedAssociations'] = includedAssociations;
    }

    Object.entries(filterData).forEach(([ key, value ]) => {
      if (value !== undefined) {
        requestParams[key] = value;
      }
    });

    if (sortColumn) {
      requestParams['sortColumn'] = sortColumn;
      requestParams['sortDirection'] = sortDirection || 'ASC';
    }

    return this.http.get(`${environment.expressUrl}/courses/reports`,
      {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params: requestParams,
      })
      .subscribe({
        next: (response: any) => {
          this._courseData$.next(response.data || []);
        },
        error: (error) => {
          this._courseData$.next(error);
          console.log('error', error);
        },
      })

  }

  generateCourseReportCSV = (exportRequest: any) => {
    return this.http.post(
      `${environment.expressUrl}/courses/reports/generate`,
      {
        'loginName': this._user$.loginName,
        'courseItemIds': exportRequest.courseItemIds,
        'requirementIds': exportRequest.requirementIds,
        'excludeUsersWithoutStateIssuedId': exportRequest.excludeUsersWithoutStateIssuedId,
        'includePreviouslyExported': exportRequest.includePreviouslyExported,
      },
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    )
  }
  // #endregion COURSE EXPORT REQUESTS
}
