<div class="container">
  <div class="content">
    <div class="title-row">
      <span class="ktitle-tiny">General Information</span>
      <span class="klabel-medium">*Required field</span>
    </div>
    <div class="section">
      <span class="klabel-huge">Basic Information</span>
      <div class="row">
        <app-input
          [label]="'First Name*'"
          [value]="user.firstName"
          [disabled]="!isAdminUser"
          [placeholder]="'Enter First Name'"
          (updatedValue)="updateUserParameter($event, 'firstName')"
          [required]="true"
        ></app-input>
        <app-input
          [label]="'Last Name*'"
          value="{{ user.lastName }}"
          [disabled]="!isAdminUser"
          [placeholder]="'Enter Last Name'"
          (updatedValue)="updateUserParameter($event, 'lastName')"
          [required]="true"
        ></app-input>
      </div>
      <!-- TODO - handle name change flag -->
      <!-- <div class="row left" *ngIf="isAdminUser"> -->
      <!-- <input
          type="checkbox"
          [(ngModel)]="this.localEditedUser.nameChangedFlag"
        />
        <span class="kbody-medium"
          >This person’s name has changed within the last year.</span
        > -->
      <!-- TODO - implement help tooltip -->
      <!-- <mat-icon fontSet="material-icons-round" class="help">help</mat-icon> -->
      <!-- </div> -->
    </div>
    <div class="section">
      <span class="klabel-huge">District Information</span>
      <div class="row">
        <app-input
          [label]="'District Email*'"
          [value]="user.contactEmail"
          [placeholder]="'Enter District Email'"
          (updatedValue)="updateUserParameter($event, 'contactEmail')"
          [required]="true"
          [email]="true"
          [disabled]="isEditMode"
        ></app-input>
        <app-input
          [label]="'Alternate Email'"
          [value]="user.altEmail"
          [placeholder]="'Enter Alternate Email'"
          (updatedValue)="updateUserParameter($event, 'altEmail')"
          [email]="true"
        ></app-input>
      </div>
      <div class="row left">
        <app-input
          class="offset"
          [label]="'PIC Number'"
          [value]="user.stateIssuedId"
          [placeholder]="'Enter PIC Number'"
          (updatedValue)="updateUserParameter($event, 'stateIssuedId')"
          [disabled]="isEditMode && !permissions.USER_EDIT"
        ></app-input>
      </div>
    </div>
    <div class="section" *ngIf="isAdminUser">
      <span class="klabel-huge">Kalpa Access</span>
      <div class="row">
        <app-select
          value="{{ user.roleIds?.[0] || '' }}"
          [label]="'User Role*'"
          [options]="formattedRoles"
          (selectedValue)="updateUserParameter($event, 'roleIds', 'select')"
          placeholder="Select User Role"
          [disabled]="isEditMode && !permissions.USER_EDIT"
        ></app-select>
        <div class="switch-container">
          <label class="switch">
            <input
              [checked]="user.activeFlag === 1"
              type="checkbox"
              [disabled]="isEditMode && !permissions.USER_EDIT"
            />
            <span class="slider round"></span>
          </label>
          <span
            >This user is
            {{ user.activeFlag === 1 ? "active" : "inactive" }}</span
          >
        </div>
      </div>
    </div>
  </div>
  <div class="buttons">
    <app-button
      [text]="'Cancel'"
      [class]="'white'"
      (click)="closeDialog()"
    ></app-button>
    <app-button
      [disabled]="
        user.firstName === '' ||
        user.lastName === '' ||
        user.contactEmail === '' ||
        user.roleIds?.length === 0
      "
      [icon]="{ materialIcon: 'arrow_forward' }"
      [class]="'icon-right'"
      [text]="'Next Step'"
      (click)="updateUser()"
    ></app-button>
  </div>
</div>
