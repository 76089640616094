import {
  Component, EventEmitter, Input, Output,
} from '@angular/core';

@Component({
  selector: 'app-form-select-option',
  templateUrl: './form-select-option.component.html',
  styleUrls: [ './form-select-option.component.scss' ],
})
export class FormSelectOptionComponent {
  @Input() value: boolean;
  @Input() display: string;
  @Input() disabled: boolean | undefined = false;
  @Input() name: string;

  @Input() indeterminate?: boolean;

  @Output() handleChange = new EventEmitter<string>();

  toggleChanged(): void {
    this.handleChange.emit(this.name);
  }
}
