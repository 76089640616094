import {
  Component, Inject, Input, OnInit, ViewChild,
} from '@angular/core';
import {
  MAT_DIALOG_DATA, MatDialog, MatDialogRef,
} from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-error-5401',
  templateUrl: './error-5401.component.html',
  styleUrls: [ './error-5401.component.scss' ],
})
export class Error5401Component implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<Error5401Component>,
    public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    console.log('ngOnInit')
  }

  closeDialog = () => {
    this.dialog.closeAll()
  }

}
