import { inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree,
} from '@angular/router';
import { AuthService } from '../services/auth.service';

const MI_STATE_CODE = 'MI';

export const FromMichiganGuard = () => {
  const router = inject(Router);
  const authService = inject(AuthService);
  let entityData: any;

  authService.user$.subscribe((userFromService: any) => {
    entityData = userFromService.Entity;
  });

  const stateCode = entityData.stateCode;

  const isFromMI = stateCode === MI_STATE_CODE;

  if (!isFromMI) {
    router.navigate([ '/', 'home' ])
    return false;
  }

  return stateCode === MI_STATE_CODE;
}
