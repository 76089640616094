<app-modal
  id="filterModal"
  title="Apply Filters"
  headerIcon="sort"
  [displayModal]="displayModal"
  (closeModal)="closeFilterModal()"
>
  <div class="section">
    <div class="title">
      <h4>Basic Filters</h4>
    </div>
    <app-form-select-group
      [selectOptions]="selectOptions"
      (optionsChanged)="handleSelectOptionsChange()"
    />
  </div>

  <div class="section">
    <div class="title">
      <h4>Filter by date & time</h4>
    </div>
    <app-form-date-picker id="date-picker" />
  </div>

  <div class="section">
    <div class="title">
      <h4>Filter by requirements, categories & tags</h4>
    </div>
    <div class="content"></div>
  </div>
</app-modal>
