<div class="checkbox-area">
  <div class="option" *ngIf="selectOptions.includeSelectAll">
    <app-form-select-option
      [name]="'selectAll'"
      [value]="allSelected"
      [indeterminate]="isIndeterminate"
      [display]="selectOptions.selectAllLabel || 'Select All'"
      (handleChange)="toggleSelectAll()"
    />
  </div>

  <div class="option" *ngFor="let option of selectOptions.options">
    <app-form-select-option
      [name]="option.name"
      [value]="option.value"
      [disabled]="option?.disabled"
      [display]="option.display"
      (handleChange)="toggleChanged($event)"
    />
  </div>
</div>
