import {
  Component, EventEmitter, Input, OnInit, Output, ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import UserGenerator from 'src/app/classes/user.class';

@Component({
  selector: 'app-buildings-and-positions',
  templateUrl: './buildings-and-positions.component.html',
  styleUrls: [ './buildings-and-positions.component.scss' ],
})
export class BuildingsAndPositionsComponent implements OnInit {
  @ViewChild('buildinginput') buildingInput: any;
  @ViewChild('positioninput') positionInput: any;
  @Output() updateUserObject = new EventEmitter<object>();
  @Output() stepBack = new EventEmitter<any>();
  @Input() user: UserGenerator;
  @Input() isEditMode: boolean;
  @Input() permissions: any;
  @Input() buildings: any;
  @Input() positions: any;
  @Input() step: { text: string; completed: boolean; modified: boolean; };

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
    console.log('is this needed?');
  }

  filterPrimaryAsset(assets: number[], primaryAssetId: number) {
    return assets.filter((assetId) => assetId != primaryAssetId);
  }

  // #region BUILDINGS
  getBuildingName = (buildingId: number | string) => {
    if (!this.buildings) return;

    return this.buildings.find((building: any) => building.id == buildingId)?.buildingName;
  }

  togglePrimaryAsset = (assetId: number, type: 'position' | 'building') => {
    if (type === 'building') {
      if (this.user.primaryBuildingId === assetId) return;

      this.user.primaryBuildingId = assetId;
      return;
    } else if (type === 'position') {

      if (this.user.primaryPositionId === assetId) return;

      this.user.primaryPositionId = assetId;
    }

    this.step.modified = true;
  }

  addBuilding = (event: Event) => {
    const buildingId = Number((event.target as HTMLInputElement).value);
    if (this.user.buildingIds.includes(buildingId)) return;

    this.user.buildingIds = [ ...this.user.buildingIds, buildingId ];
    this.buildingInput.nativeElement.value = '';

    if (!this.user.primaryBuildingId) {
      this.user.primaryBuildingId = buildingId;
    }
    this.step.modified = true;
  }

  removeBuilding = (buildingId: number | string) => {
    const primaryBuildingId = this.user.primaryBuildingId;

    const isPrimary = primaryBuildingId === buildingId;

    const newBuildingsArr = this.user.buildingIds.filter((item: any) => item !== buildingId);

    this.user.buildingIds = newBuildingsArr;

    if (isPrimary) {
      this.user.primaryBuildingId = newBuildingsArr[0];
    }

    this.step.modified = true;
  }



  // #endregion BUILDINGS

  // #region POSITIONS

  getPositionName = (positionId: number | string) => {
    if (!this.positions) return;

    return this.positions.find((position: any) => position.id == positionId)?.positionTitle;
  }

  addPosition = (event: Event) => {
    const positionId = Number((event.target as HTMLInputElement).value);
    if (this.user.positionIds.includes(positionId)) return;

    this.user.positionIds = [ ...this.user.positionIds, positionId ];
    this.positionInput.nativeElement.value = '';

    if (!this.user.primaryPositionId) {
      this.user.primaryPositionId = positionId;
    }

    this.step.modified = true;
  }

  removePosition = (positionId: number | string) => {
    const primaryPositionId = this.user.primaryPositionId;

    const newBuildingsArr = this.user.positionIds.filter((item: any) => item !== positionId);

    this.user.positionIds = newBuildingsArr;

    const isPrimary = primaryPositionId === positionId;
    if (isPrimary) {
      this.user.primaryPositionId = newBuildingsArr[0];
      return;
    }

    this.step.modified = true;
  }

  // #endregion POSITIONS

  stepBackward = () => {
    this.stepBack.emit();
  }

  closeDialog = () => {
    this.dialog.closeAll();
  }

  updateUser = () => {
    if (!this.user.primaryBuildingId || !this.user.primaryPositionId) {
      return
    }
    this.updateUserObject.emit();
  }

}
