import {
  Component, EventEmitter, Input, OnInit, Output,
} from '@angular/core';

export type SelectOption = {
  name: string;
  value: boolean;
  display: string;
  disabled?: boolean;
};

export type SelectGroup = {
  includeSelectAll?: boolean;
  selectAllLabel?: string;
  indentOptions?: boolean;

  customSelectColor?: string;
  options: SelectOption[];
};

@Component({
  selector: 'app-form-select-group',
  templateUrl: './form-select-group.component.html',
  styleUrls: [ './form-select-group.component.scss' ],
})
export class FormSelectGroupComponent implements OnInit {
  @Input() selectOptions: SelectGroup;
  @Output() optionsChanged = new EventEmitter<void>();

  allSelected = false;
  isIndeterminate = false;

  ngOnInit() {
    this.checkSelection();
  }


  handleOptionChange(): void {
    this.optionsChanged.emit();
  }

  checkSelection() {
    this.allSelected = this.selectOptions.options.every(option => option.value);

    this.isIndeterminate = this.selectOptions.options.some(option => option.value) && !this.allSelected;
  }

  toggleSelectAll() {
    this.allSelected = !this.allSelected;

    this.selectOptions.options.forEach(option => {
      option.value = this.allSelected;
    });
  }

  toggleChanged(prop: string) {
    const changedOption = this.selectOptions.options.find(option => option.name === prop);

    if (changedOption) {
      changedOption.value = !changedOption.value;
    }
    this.checkSelection();
  }

}
