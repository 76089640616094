<div class="header-row" ngProjectAs="[header]">
  <div class="left header-side">
    <h4>Finish and Export</h4>
  </div>
  <div class="right header-side">
    <app-new-button
      (clickHandler)="previousStep()"
      leftIcon="arrow_back"
      size="sm"
      color="bare"
      customPadding="12px 16px"
      >Go Back</app-new-button
    >
    <app-new-button
      (clickHandler)="finishAndExport()"
      size="sm"
      color="green"
      customPadding="12px 16px"
      [disabled]="false"
    >
      Finish and Export
    </app-new-button>
  </div>
</div>

<ng-container>
  <div class="finish-export-section">
    <div class="section-heading">
      <span class="additional-options">Additional Options</span>
    </div>
    <div class="section-content">
      <!-- <div class="configure-options-hint">
        <span>Configure these options in <span (click)="showExportSettings()" class="export-settings-link">Export Settings</span>.</span>
      </div> -->
      <div class="current-export-settings">
        <div class="top-row">
          <div class="export-setting">
            <span class="export-setting-label">Form Output</span>
            <span class="export-setting-value">MOECS</span>
          </div>
          <div class="export-setting">
            <span class="export-setting-label">Include Previously Exported Data</span>
            <input type="checkbox"
              [checked]="includePreviouslyExportedData"
              (change)="toggleIncludePreviouslyExportedData()"
            />
          </div>
          <div class="export-setting">
            <span class="export-setting-label">Group By</span>
            <span class="export-setting-value">Course Date</span>
          </div>
        </div>
        <div class="bottom-row">
          <!-- <div class="export-setting">
            <span class="export-setting-label">Position Exclusions</span>
            <span class="export-setting-value">None</span>
          </div> -->
          <!-- <div class="export-setting">
            <span class="export-setting-label">User Exclusions</span>
            <span class="export-setting-value">None</span>
          </div> -->
          <div class="export-setting">
            <span class="export-setting-label">Exclude Users without PIC</span>
            <input type="checkbox"
              [checked]="excludeUsersWithoutPIC"
              (change)="toggleExcludeUsersWithoutPIC()"
            />
          </div>
        </div>
      </div>
      <div class="section-heading">
        <span class="additional-options">Selected Requirements</span>
      </div>
      <div class="requirements-chips-container">
        <div class="top">
          <span class="requirements-chips-title">Requirements Selected</span>
          <!-- <div class="remember-selections-container">
            <input type="checkbox"
              [checked]="rememberSelections"
              (change)="toggleRememberSelections()"
            />
            <span>Remember Selections</span>
          </div> -->
        </div>
        <div class="requirements-chips">
          <ng-container *ngFor="let requirement of requirements">
            <div class="chip">
              <span>{{ requirement.name }}</span>
            </div>
          </ng-container>
        </div>
      </div>
      <app-new-table
        [tableData]="tableData"
      />
    </div>
  </div>
</ng-container>
