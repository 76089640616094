<div class="modal">
  <div class="title-row">
    <div class="title-container">
      <mat-icon fontSet="material-icons-round">warning</mat-icon>
      <span class="kbody-huge">File Contains No Data</span>
    </div>
    <mat-icon class="close-icon" (click)="closeDialog()" fontSet="material-icons-round">close</mat-icon>
  </div>
  <div class="content">
    <div class="tab-content">
      <span class="error-description">The data export process could not be completed. Your attendee file would contain no data.</span>
      <span class="error-heading">Some possibilities that could have caused this error:</span>
      <ul>
        <li>You selected a requirement to include in this course export, but no users meet that requirement for the selected course.</li>
        <li>You have selected to exclude users without a PIC number, and none of the registrants of this course have a PIC number listed on their Kalpa profile.</li>
      </ul>
    </div>
    <div class="edit-buttons">
      <app-button [text]="'Okay'" (click)="closeDialog()"></app-button>
    </div>
  </div>
</div>
