<div id="date-picker">
  <input
    id="date-picker-input"
    type="text"
    placeholder="Select Date"
    readonly
    (click)="toggleCalendar()"
  />

  <app-pop-up-menu [open]="showCalendar" parentId="date-picker-input">
    This is the content of the pop-up menu
  </app-pop-up-menu>
</div>
