import {
  Component, OnDestroy, OnInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { UsersService } from 'src/app/services/users.service';
import { UpdateUserComponent } from './components/update-user/update-user.component';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-staff-list',
  templateUrl: './staff-list.component.html',
  styleUrls: [ './staff-list.component.scss' ],
})
export class StaffListComponent implements OnInit, OnDestroy {
  loading = false;
  filterString = '';
  tableData: any;
  userListData: any;
  permissions: any;
  selectedItemsPerPage = 25;
  isActive = false;

  searchText = '';
  sortColumnName = 'firstName';
  sortDirection: 'ASC' | 'DESC' = 'ASC';
  offset = 0;

  // #region Subscriptions
  userListSubscription = this.usersService.usersData$.subscribe((data: any) => {
    if (!data) return;
    this.userListData = data;
    const formattedTableData = this?.userListData?.rows?.map((user: any) => {
      return {
        itemId: user.id,
        itemData: [
          { content: user?.fullName, class: 'super-skinny left-align' },
          { content: user?.PrimaryPosition?.positionTitle, class: 'super-skinny left-align' },
          { content: user?.PrimaryBuilding?.buildingName, class: 'super-skinny left-align' },
          { content: user?.contactEmail, class: 'super-skinny left-align' },
          { content: user?.Roles?.[0]?.roleName, class: 'super-skinny left-align' },
          // {
          //   type: 'staff options', class: 'super-skinny', notClickable: true,
          // },
        ],
      }
    })

    this.tableData = {
      tableTitle: 'Staff',
      error: data?.error || null,
      noTitleSlice: true,
      noLineButtons: true,
      isStaffList: true,
      curPage: data?.pagination.curPage,
      totalPages: data?.pagination.totalPages,
      totalItems: data?.pagination.totalItems,
      activeTotal: data?.counts.activeCount,
      inactiveTotal: data?.counts.inactiveCount,
      columnTitles: this.tableData.columnTitles,
      data: formattedTableData,
    }
    this.userListData ? this.loading = false : null;
  })

  authSubscription = this.authService.permissions$.subscribe((permissions) => {
    this.permissions = permissions;
  });
  // #endregion

  constructor(
    private usersService: UsersService,
    public dialog: MatDialog,
    private authService: AuthService,
    private router: Router,
  ) { }

  // TODO: make sure this is being informed corrctly by the backend regarding the users accessLevel (primary, secondary, all users)
  // TODO: update ui and sorting functionality

  ngOnInit(): void {
    this.tableData = {
      tableTitle: 'Staff',
      noTitleSlice: true,
      noLineButtons: true,
      columnTitles: [
        {
          title: 'Member Name', class: 'super-skinny left-align', sortBy: 'firstName',
        },
        {
          title: 'Position', class: 'super-skinny left-align', sortBy: 'PrimaryPosition.position_title',
        },
        {
          title: 'Building', class: 'super-skinny left-align', sortBy: 'PrimaryBuilding.building_name',
        },
        {
          title: 'Email Address', class: 'super-skinny left-align', sortBy: 'contactEmail',
        },
        {
          title: 'User role', class: 'super-skinny left-align', sortBy: 'Roles.role_name',
        },
        // { title: 'Options', class: 'super-skinny left-align' },
      ],
      data: [],
    };

    this.loading = true;
    this.searchForUsers();

  }

  searchForUsers() {
    this.loading = true;
    this.tableData.data = [];
    const includedAssociations = 'PrimaryBuilding, PrimaryPosition, Roles'

    const params = {
      limit: this.selectedItemsPerPage,
      offset: this.offset,
      sortColumn: this.sortColumnName,
      sortDirection: this.sortDirection,
      activeFlag: this.isActive ? 0 : 1 as 0 | 1,
      userNameFilter: this.searchText,
      includedAssociations,
    }

    this.usersService.getUsers(params);
  }

  calculateOffset(page: number): number {
    return (page - 1) * this.selectedItemsPerPage;
  }

  onPageChanged(page: number) {
    const offset = this.calculateOffset(page);
    this.offset = offset;
    this.searchForUsers();
  }

  onItemsPerPageChange(itemsPerPage: number) {
    this.selectedItemsPerPage = itemsPerPage;
    this.offset = 0;
    this.searchForUsers();
  }

  getStaffFilteredByName = (searchItem: any) => {
    this.searchText = searchItem?.searchText;
    this.searchForUsers();
  }

  sortColumn(sortOrder: any) {
    this.sortColumnName = sortOrder.sortBy;
    this.sortDirection = sortOrder.direction;
    this.offset = 0;

    this.searchForUsers();
  }

  toggleActiveList = (showActiveItems: boolean) => {
    this.isActive = showActiveItems;
    this.searchForUsers();
  }

  addNewStaff = () => {
    const dialog = this.dialog.open(UpdateUserComponent);

    dialog.afterClosed().subscribe((result) => {
      if (result.statusCode === 1000 || result === 'reload') {
        this.searchForUsers();
      }
    });
  }

  showStaffEditModal = (itemId: any) => {
    const user = this.userListData.rows.find((user: any) => user.id === itemId);

    const editModal = this.dialog.open(UpdateUserComponent, { data: user })

    editModal.afterClosed().subscribe((result) => {
      if (result?.statusCode === 1000 || result === 'reload') {
        this.searchForUsers();
      }
    });
  }

  paginationEvent = (pageDirection: string) => {
    this.loading = true;
    this.tableData.data = [];
    this.usersService.getPagination(pageDirection);
  }

  onRowClicked(item: any) {
    const itemId = item.itemId;
    const itemRouterLink = `/profile/${itemId}`

    //navigate to itemRouterLink
    this.router.navigate([ itemRouterLink ]);
  }

  ngOnDestroy(): void {
    this.userListSubscription.unsubscribe();
    this.authSubscription.unsubscribe();
  }

}
