import {
  Component, Inject, OnDestroy, OnInit,
} from '@angular/core';
import {
  MAT_DIALOG_DATA, MatDialog, MatDialogRef,
} from '@angular/material/dialog';
import { VerificationComponent } from 'src/app/components/verification/verification.component';
import { BuildingsService } from 'src/app/services/buildings.service';
import { AuthService } from 'src/app/services/auth.service';
import { EntityService } from 'src/app/services/entity.service';
import { Subscription } from 'rxjs';
import { BatchReassignComponent } from 'src/app/components/batch-reassign/batch-reassign.component';
import { ToastService } from 'src/app/services/toast.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-update-building',
  templateUrl: './update-building.component.html',
  styleUrls: [ './update-building.component.scss' ],
})
export class UpdateBuildingComponent implements OnInit, OnDestroy {
  building: any = {}
  buildingDetails: any;
  error: any;
  user: any;
  addBuildingSubscription: Subscription;
  authSubscription: Subscription;
  buildingSubscription: Subscription;
  userSubscription: Subscription;
  entitySubscription: Subscription;
  subscribeDialog: Subscription;
  allMoved = false;
  buildingsList: any;
  stateCodeList = [
    { text: 'AL', value: 'AL' }, { text: 'AK', value: 'AK' }, { text: 'AS', value: 'AS' }, { text: 'AZ', value: 'AZ' }, { text: 'AR', value: 'AR' }, { text: 'CA', value: 'CA' }, { text: 'CO', value: 'CO' }, { text: 'CT', value: 'CT' }, { text: 'DE', value: 'DE' }, { text: 'DC', value: 'DC' }, { text: 'FM', value: 'FM' }, { text: 'FL', value: 'FL' }, { text: 'GA', value: 'GA' }, { text: 'GU', value: 'GU' }, { text: 'HI', value: 'HI' }, { text: 'ID', value: 'ID' }, { text: 'IL', value: 'IL' }, { text: 'IN', value: 'IN' }, { text: 'IA', value: 'IA' }, { text: 'KS', value: 'KS' }, { text: 'KY', value: 'KY' }, { text: 'LA', value: 'LA' }, { text: 'ME', value: 'ME' }, { text: 'MH', value: 'MH' }, { text: 'MD', value: 'MD' }, { text: 'MA', value: 'MA' }, { text: 'MI', value: 'MI' }, { text: 'MN', value: 'MN' }, { text: 'MS', value: 'MS' }, { text: 'MO', value: 'MO' }, { text: 'MT', value: 'MT' }, { text: 'NE', value: 'NE' }, { text: 'NV', value: 'NV' }, { text: 'NH', value: 'NH' }, { text: 'NJ', value: 'NJ' }, { text: 'NM', value: 'NM' }, { text: 'NY', value: 'NY' }, { text: 'NC', value: 'NC' }, { text: 'ND', value: 'ND' }, { text: 'MP', value: 'MP' }, { text: 'OH', value: 'OH' }, { text: 'OK', value: 'OK' }, { text: 'OR', value: 'OR' }, { text: 'PW', value: 'PW' }, { text: 'PA', value: 'PA' }, { text: 'PR', value: 'PR' }, { text: 'RI', value: 'RI' }, { text: 'SC', value: 'SC' }, { text: 'SD', value: 'SD' }, { text: 'TN', value: 'TN' }, { text: 'TX', value: 'TX' }, { text: 'UT', value: 'UT' }, { text: 'VT', value: 'VT' }, { text: 'VI', value: 'VI' }, { text: 'VA', value: 'VA' }, { text: 'WA', value: 'WA' }, { text: 'WV', value: 'WV' }, { text: 'WI', value: 'WI' }, { text: 'WY', value: 'WY' },
  ];


  constructor(
    private authService: AuthService,
    private buildingsService: BuildingsService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<UpdateBuildingComponent>,
    private toastService: ToastService,
    private entityService: EntityService,
    private userService: UsersService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.authSubscription = this.authService.user$.subscribe((user: any) => this.user = user)

    this.entityService.getEntity('Buildings')
    this.entitySubscription = this.entityService.entityData$.subscribe((entity: any) => {
      if (!entity?.Buildings) return
      this.buildingsList = entity.Buildings
    })

    this.building = data?.buildingData || {}
  }

  ngOnInit(): void {
    // get building details so that we have the supervisorID and user count as well as data being passed in
    if(this.data) {
      this.buildingSubscription = this.buildingsService.getBuildingDetails(this.building.id, 'Users, UserCount').subscribe({
        next: (response: any) => {
          // set local building details to know the user count for deactivation
          this.buildingDetails = response.data;

        },
        error: (error) => {
          console.log('error', error);
        },
      });
    }

    // todo - get list of users for supervisor dropdown

    // this.userService.getUsers('', 0);
    // this.userService.usersData$.subscribe((data: any) => {
    //   if (data) {
    //     const formattedUsersList = data.rows.map((user: any) => {
    //       return {
    //         name: user.fullName,
    //         value: user.id,
    //       }
    //     })
    //     console.log('🚀 - formattedUsersList:', formattedUsersList);
    //   }
    // });
  }

  updateBuildingParameter = (event: any, parameter: string) => {
    if(typeof event === 'string') {
      this.building[parameter] = event;
      return
    }
    this.building[parameter] = event?.target?.value;
  }

  saveBuilding = () => {
    if (!this.building.buildingName) {
      this.error = { name: 'Please enter a building name' };
    }
    else if (!this.building.buildingType) {
      this.error = { type: 'Please enter a building type' };
    }
    else {
      this.addBuildingSubscription = (this.data ? this.buildingsService.updateBuilding(this.building) : this.buildingsService.addBuilding(this.building))
        .subscribe({
          next: (response: any) => {
            this.dialogRef.close(response);
            this.toastService.setToast({ text: this.data ? 'Changes Saved' : 'Building successfully added', type: 'success' })
          },
          error: (error: any) => {
            this.dialog.open(VerificationComponent, {
              data: {
                type: 'alert', title: `Oops, something went wrong creating ${this.building.buildingName}`, text: error.message,
              },
            })
          },
        })
    }
  }

  setActivation = (setActive: boolean) => {
    // if there are still assigned users, then the user must reassign them before deactivating
    if(this.buildingDetails.Users.length > 0) {
      // get the active users from the user list to display in the modal warning
      const activeUserList = this.buildingDetails.Users.filter((user: any) => user.activeFlag === 1);
      // format for the reassign modal
      const formattedUserList = activeUserList.map((user: any) => {
        return {
          name: user.fullName,
          id: user.id,
        }
      })
      // format reassign list for the reassign modal and exclude the current building
      const filteredPotentialReassignList = this.buildingsList.filter((building: any) => building.id !== this.data.id && building.activeFlag == 1);
      const formattedPotentialReassignList = filteredPotentialReassignList.map((building: any) => {
        return {
          name: building.buildingName,
          id: building.id,
        }
      })
      // fire a warning modal
      const dialogRef = this.dialog.open(VerificationComponent, {
        data: {
          type: 'alert',
          title: 'Reassign staff',
          confirmButtonTitle: 'Reassign Staff',
          cancelButtonTitle: 'Cancel Deactivation',
          text: `There are ${this.buildingDetails.UserCount.activeUsers} staff members assigned to this building. You must assign all active users to a new building before deactivating.`,
        },
      })
      // if the user confirms, then open the reassign modal
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          const dialogData = {
            systemSettingName: 'building',
            titleText: 'Reassign staff to new building',
            titleChoose: 'Choose a new building to reassign staff to*',
            needToMoveList: formattedUserList,
            potentialReassignList: formattedPotentialReassignList,
          };
          const batchDialogRef = this.dialog.open(BatchReassignComponent, { data: dialogData });
          // if the batch dialog is closed after all users have been reassigned, we can deactivate the building, so we toggle a local boolean
          batchDialogRef.afterClosed().subscribe(result => {
            if(result === 'success') {
              this.allMoved = true
              this.dialogRef.close('reload')
            }
          })
          // this is the way that we listen to the dialog's emitter for any batch reassignments
          this.subscribeDialog = batchDialogRef.componentInstance.moveObjectEmitter.subscribe((data: any) => {
            const formattedStaffList = data.movingList.map((user: any) => parseFloat(user.id));
            // in the case of a fired batch reassign, we make the call through our service
            this.buildingsService.batchStaffReassign(
              formattedStaffList, this.buildingDetails.id, data.selectedTarget.id,
            ).subscribe({
              next: (response: any) => {
                this.toastService.setToast({ text: 'Staff reassigned', type: 'success' })
                // if all staff have been moved, then we can deactivate the building
                if(this.allMoved === true) {
                  this.buildingsService.toggleBuildingActivation(this.building, setActive).subscribe({
                    next: (response: any) => {
                      this.toastService.setToast({ text: 'Building Deactivated', type: 'success' })
                      this.dialogRef.close(response)
                    },
                    error: (error: any) => {
                      console.log('error', error)
                    },
                  })
                }
              },
              error: (error) => {
                console.log('error', error);
              },
            })
          })
        }
      })
    } else {
      // if there are no assigned users, then we can just deactivate the building
      this.buildingsService.toggleBuildingActivation(this.building, setActive).subscribe({
        next: (response: any) => {
          this.toastService.setToast({ text: `Building ${setActive ? 'Activated' : 'Deactivated'}`, type: 'success' })
          setActive ? this.buildingsService.getInactiveBuildings() : this.buildingsService.getBuildings();
          this.dialogRef.close(response);
        },
        error: (error: any) => {
          console.log('error', error)
        },
      })
    }
  }

  closeDialog = () => {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this?.addBuildingSubscription?.unsubscribe();
    this?.authSubscription?.unsubscribe();
    this?.buildingSubscription?.unsubscribe();
    this?.userSubscription?.unsubscribe();
    this?.subscribeDialog?.unsubscribe();
    this?.entitySubscription?.unsubscribe();
  }

}
