import { ItemFlatNode } from '../routes/manage-staff/components/staff-list/components/update-user/components/user-requirements/user-requirements.component';

const cloneObject = <T>(obj: T): T => JSON.parse(JSON.stringify(obj));

// #region INTERFACES
interface UserRequestAttributes {
  activeFlag: 1 | 0;
  firstName: string;
  lastName: string;
  contactEmail: string;
  altEmail?: string;
  otherFirstNames?: string;
  otherLastNames?: string;

  ldapDn?: string;
  stateIssuedId?: string;

  primaryBuildingId: number;
  primaryPositionId: number;
}

interface APIUser extends UserRequestAttributes {
  loginName: string;

  Buildings: any[];
  Positions: any[];

  PrimaryApprovers: any[];
  SecondaryApprovers: any[];

  Requirements: any[];
  RequirementsTree: any[];

  Roles: any[];
}

type SingleChildFormattedRequirement = {
  requirementId: number;
  requiredValue: number;
}

type FormattedRequirementParams= {
  requirement?: string;
  userId?: number;
  requirementId: number;
  openingBalance?: number;
  rollingNthYear?: number;
  requiredValue: number | string;
  userRequirementId?: number;

  childrenRequiredValues?: SingleChildFormattedRequirement[]
}

type PendingRequirementParams = {
  requirement: string;
  id: number;
  parentId: number;
  topMostParentId: number;
  trackingValue: number | string;
  openingBalance?: number;
  rollingNthYear?: number;
  nodeType: string;
}

interface UserRequestAttributes {
  id?: number;
  activeFlag: 1 | 0;
  firstName: string;
  lastName: string;
  contactEmail: string;
  altEmail?: string;
  otherFirstNames?: string;
  otherLastNames?: string;

  ldapDn?: string;
  stateIssuedId?: string;

  primaryBuildingId: number;
  primaryPositionId: number;
}

interface CreateUserRequestAttributes extends UserRequestAttributes {
  userLoginName?: string;
  primaryApproverIds: number[];
  secondaryApproverIds: number[];
  buildingIds: number[];
  positionIds: number[];

  requirementsArr?: FormattedRequirementParams[];
  roleIds?: number[];
}

type ArrayProps = 'primaryApproverIds' | 'secondaryApproverIds' | 'buildingIds' | 'positionIds' | 'roleIds';
const arrayProps: ArrayProps[] = [
  'primaryApproverIds', 'secondaryApproverIds', 'buildingIds', 'positionIds', 'roleIds',
];

interface UpdateUserRequestAttributes {
  activeFlag?: 1 | 0;
  firstName?: string;
  lastName?: string;
  contactEmail?: string;
  altEmail?: string;
  otherFirstNames?: string;
  otherLastNames?: string;

  ldapDn?: string;
  stateIssuedId?: string;

  primaryBuildingId?: number;
  primaryPositionId?: number;

  addBuildingIds?: number[];
  removeBuildingIds?: number[];

  addPositionIds?: number[];
  removePositionIds?: number[];

  addRoleIds?: number[];
  removeRoleIds?: number[];

  addRequirementsArr?: FormattedRequirementParams[];
  updateRequirementsArr?: FormattedRequirementParams[];
  removeRequirementsArr?: number[];

  addPrimaryApproverIds?: number[];
  removePrimaryApproverIds?: number[];

  addSecondaryApproverIds?: number[];
  removeSecondaryApproverIds?: number[];
}

// #endregion INTERFACES

class UserGenerator {
  id?: number;
  activeFlag: 1 | 0 = 1;
  firstName = '';
  lastName = '';
  contactEmail = '';
  altEmail?: string;
  otherFirstNames?: string = '';
  otherLastNames?: string = '';

  ldapDn?: string = '';
  stateIssuedId?: string = '';

  primaryBuildingId: number;
  primaryPositionId: number;

  primaryApproverIds: number[] = [];
  secondaryApproverIds: number[] = [];
  buildingIds: number[] = [];
  positionIds: number[] = [];

  requirementsArr?: FormattedRequirementParams[] = [];
  pendingRequirementsArr: PendingRequirementParams[] = [];
  roleIds?: number[] = [];

  prevValues: CreateUserRequestAttributes;

  constructor(prevProps?: APIUser) {
    if (prevProps) {
      this.prevValues = this.formatAPIUserProps(prevProps);
      this.applyPrevValues(prevProps.RequirementsTree);
    }
  }

  // #region FORMAT PREVIOUS USER PROPS
  formatAPIUserProps(apiProps: APIUser): CreateUserRequestAttributes {
    const clonedProps = cloneObject(apiProps);

    this.id = clonedProps.id;

    const formattedProps = {
      activeFlag: clonedProps.activeFlag,
      firstName: clonedProps.firstName,
      lastName: clonedProps.lastName,
      contactEmail: clonedProps.contactEmail,
      altEmail: clonedProps.altEmail,
      otherFirstNames: clonedProps.otherFirstNames,
      otherLastNames: clonedProps.otherLastNames,
      ldapDn: clonedProps.ldapDn,
      stateIssuedId: clonedProps.stateIssuedId,

      primaryBuildingId: clonedProps.primaryBuildingId,
      primaryPositionId: clonedProps.primaryPositionId,
      primaryApproverIds: clonedProps.PrimaryApprovers?.map((approver) =>
        Number(approver.id)),
      secondaryApproverIds: clonedProps.SecondaryApprovers?.map((approver) =>
        Number(approver.id)),
      buildingIds: clonedProps?.Buildings?.map((building) =>
        Number(building.id)) || [],
      positionIds: clonedProps?.Positions?.map((position) =>
        Number(position.id)) || [],
      roleIds: clonedProps.Roles?.map((role) =>
        Number(role.id)),

      requirementsArr: this.formatApiRequirements(clonedProps.Requirements),
    };

    // These lines fix the bug that was present before. It should only be needed for briefly, but it doesn't hurt to keep this here.
    const primaryBuildingMissing = !formattedProps.buildingIds?.includes(formattedProps.primaryBuildingId);
    const primaryPositionMissing = !formattedProps.positionIds?.includes(formattedProps.primaryPositionId);

    if (primaryBuildingMissing) {
      formattedProps.buildingIds?.push(formattedProps.primaryBuildingId);
    }

    if (primaryPositionMissing) {
      formattedProps.positionIds?.push(formattedProps.primaryPositionId);
    }

    return cloneObject(formattedProps);
  }

  formatApiRequirements(requirements: any[]): FormattedRequirementParams[] {
    const parentAndSingleRequirements = requirements.filter((req) => req.parentRequirementId === null);
    return parentAndSingleRequirements.map((req) => this.formatSingleRequirement(req, requirements));
  }


  formatSingleRequirement(req: any, fullRequirementsArr: any[]): FormattedRequirementParams {
    const userReq = req.UserRequirements;

    const flattenedRequirement: FormattedRequirementParams = {
      userRequirementId: userReq.id,
      requirementId: userReq.requirementId,
      requirement: req.requirement,
      // parentId: req.parentRequirementId,
      // topMostParentId: req.topMostParentId,
      requiredValue: userReq.requiredValue,
      // nodeType: '',


      openingBalance: userReq.openingBalance,
      rollingNthYear: userReq.rollingNthYear,
      childrenRequiredValues: this.getChildrenRequirementDetails(req, fullRequirementsArr),
    }

    return flattenedRequirement;
  }

  getChildrenRequirementDetails(req: any, fullRequirementsArr: any[]) {
    const childrenRequirements = fullRequirementsArr.filter((fullReq) => fullReq.parentRequirementId === req.id);
    const grandChildrenRequirements = childrenRequirements.map((childReq) => fullRequirementsArr.filter((fullReq) => fullReq.parentRequirementId === childReq.id));

    const allChildrenArr = [ ...childrenRequirements, ...grandChildrenRequirements.flat() ];

    const childrenRequiredValues = allChildrenArr.map((childReq) => {
      const userReq = childReq.UserRequirements;

      return {
        requirementId: userReq.requirementId,
        requiredValue: userReq.requiredValue,
      }
    });

    return childrenRequiredValues;
  }


  // #endregion FORMAT PREVIOUS USER PROPS

  // #region HANDLE REQUIREMENTS
  checkRequirementIsSelected(reqId: number) {
    // const inOriginal = this.requirementsArr?.some((req) => req.requirementId == reqId);
    const inPending = this.pendingRequirementsArr?.some((req) => req.id == reqId);

    return !!(inPending);
  }


  addRequirements(requirementNodes: ItemFlatNode[]) {
    const pendingRequirements = this.pendingRequirementsArr;
    const requirementIds = requirementNodes.map((node) => node.id);

    const filteredPending = pendingRequirements?.filter((req) => {
      return !requirementIds.includes(req.id);
    }) || [];

    const newRequirements = [ ...filteredPending, ...requirementNodes ];
    this.pendingRequirementsArr = newRequirements;
    return newRequirements;
  }

  removeRequirements(requirementNodes: ItemFlatNode[]) {
    const requirementIds = requirementNodes.map((node) => node.id);

    this.pendingRequirementsArr =
      this.pendingRequirementsArr?.filter((req) => {
        return !requirementIds.includes(req.id);
      });
  }

  toggleRequirements(requirementNodes: ItemFlatNode[], isSelected: boolean) {
    if (isSelected) {
      this.addRequirements(requirementNodes);
      return;
    }

    this.removeRequirements(requirementNodes);
  }

  updateRequirementValue(reqId: number, trackingValue: number | string) {
    const pendingRequirements = this.pendingRequirementsArr;

    const updatedRequirements = pendingRequirements?.map((req) => {
      if (req.id === reqId) {
        return { ...req, trackingValue };
      }

      return req;
    });

    this.pendingRequirementsArr = updatedRequirements;
  }

  // #endregion HANDLE REQUIREMENTS

  // #region UTILS
  applyPrevValues(requirementsTree: any[]) {
    const prevValues =cloneObject(this.prevValues);

    Object.entries(prevValues).forEach(([ key, value ]) => {
      // @ts-ignore
      this[key] = value;
    });
    this.populateInitialPendingRequirements(requirementsTree);
  }

  formatSingleFormattedIntoPending(req: FormattedRequirementParams, requirementTree: any[]) {
    const foundTreeReq = requirementTree.find((treeReq) => treeReq.requirementId === req.requirementId);

    if (!foundTreeReq) {
      throw new Error('Requirement not found in tree for user');
    }

    const pendingReq: PendingRequirementParams = {
      requirement: foundTreeReq.requirement,
      id: foundTreeReq.requirementId,
      parentId: foundTreeReq.parentRequirementId,
      topMostParentId: foundTreeReq.topMostParentId,
      trackingValue: req.requiredValue,
      openingBalance: foundTreeReq.UserRequirements.openingBalance,
      rollingNthYear: foundTreeReq.UserRequirements.rollingNthYear,
      nodeType: foundTreeReq.nodeType,
    };

    this.pendingRequirementsArr.push(pendingReq);

    req.childrenRequiredValues?.forEach((childReq) => {
      const foundTreeChildReq = requirementTree.find((treeReq) => treeReq.requirementId === childReq.requirementId);

      if (!foundTreeChildReq) {
        throw new Error('Requirement not found in tree for user');
      }

      const pendingChildReq: PendingRequirementParams = {
        id: foundTreeChildReq.requirementId,
        trackingValue: childReq.requiredValue,

        requirement: foundTreeChildReq.requirement,
        parentId: foundTreeChildReq.parentRequirementId,
        topMostParentId: foundTreeChildReq.topMostParentId,
        nodeType: foundTreeChildReq.nodeType,
      };

      this.pendingRequirementsArr.push(pendingChildReq);
    });
  }

  populateInitialPendingRequirements(requirementsTree: any[]) {
    const requirements = this.requirementsArr;

    if (!requirements) return;

    requirements.forEach((req) =>
      this.formatSingleFormattedIntoPending(req, requirementsTree));
  }

  updateValue(key: keyof UserGenerator, value: any) {
    const keyIsArrayProp = arrayProps.includes(key as ArrayProps);

    if (keyIsArrayProp) {
      this.updateArrayValue(key as ArrayProps, value);
      return;
    }

    // @ts-ignore
    this[key] = value;
  }

  updateArrayValue(key: ArrayProps, value: any) {
    if (key === 'roleIds') {
      this.roleIds = [ Number(value) ];
    }
  }

  // #endregion UTILS

  // #region GENERATE REQUESTS
  formatSinglePendingRequirement(req: PendingRequirementParams, childRequirements: PendingRequirementParams[] = []): FormattedRequirementParams {
    const formattedRequirement: FormattedRequirementParams = {
      // requirement: req.requirement,
      requirementId: Number(req.id),
      requiredValue: Number(req.trackingValue),
      openingBalance: Number(req.openingBalance) as number,
      rollingNthYear: Number(req.rollingNthYear) as number,
      childrenRequiredValues: childRequirements.map((childReq) => {
        return {
          requirementId: Number(childReq.id),
          requiredValue: Number(childReq.trackingValue),
        }
      }),
    }

    return formattedRequirement;
  }

  formatPendingRequirements(pendingRequirements: PendingRequirementParams[]) {
    const parentAndSingleRequirements = pendingRequirements.filter((req) => req.nodeType === 'GROUP_PARENT' || req.nodeType === 'SINGLE');

    return parentAndSingleRequirements.map((req) => {
      const childRequirements = pendingRequirements.filter((childReq) => childReq.topMostParentId == req.id && childReq.id != req.id);
      return this.formatSinglePendingRequirement(req, childRequirements)
    });
  }

  formatPropsForRequest() {
    const formattedRequest: CreateUserRequestAttributes = {
      activeFlag: this.activeFlag,
      firstName: this.firstName,
      lastName: this.lastName,
      contactEmail: this.contactEmail,
      altEmail: this.altEmail,
      otherFirstNames: this.otherFirstNames,
      otherLastNames: this.otherLastNames,
      ldapDn: this.ldapDn,
      stateIssuedId: this.stateIssuedId,
      primaryBuildingId: this.primaryBuildingId,
      primaryPositionId: this.primaryPositionId,
      primaryApproverIds: this.primaryApproverIds,
      secondaryApproverIds: this.secondaryApproverIds,
      buildingIds: this.buildingIds,
      positionIds: this.positionIds,
      requirementsArr: this.formatPendingRequirements(this.pendingRequirementsArr),
      roleIds: this.roleIds,
    };
    return formattedRequest;
  }

  compareIdArrays(newArr: number[], prevArr: number[]): { remove: number[], add: number[] } {
    const remove = prevArr.filter((prevId) => !newArr.includes(prevId));
    const add = newArr.filter((newId) => !prevArr.includes(newId));

    return { remove, add };
  }

  // #region COMPARE PROPS
  handleArrayProps(key: ArrayProps, value: number[]) {
    const prevProps = this.prevValues;

    const addName = `add${key.charAt(0).toUpperCase()}${key.slice(1)}`;
    const removeName = `remove${key.charAt(0).toUpperCase()}${key.slice(1)}`;

    const comparisonArray = prevProps?.[key] as number[];

    const { add, remove } = this.compareIdArrays(value, comparisonArray);

    if (add.length === 0 && remove.length === 0) return {};

    if (add.length > 0 && remove.length === 0) {
      return { [addName]: add };
    }

    if (remove.length > 0 && add.length === 0) {
      return { [removeName]: remove };
    }

    if (add.length > 0 && remove.length > 0) {
      return {
        [addName]: add.map((id) => Number(id)),
        [removeName]: remove.map((id) => Number(id)),
      };
    }

    return {};
  }

  checkRequirementEqualityWithPrevious(newRequirement: FormattedRequirementParams, prevRequirements: FormattedRequirementParams) {
    // if any values are different than return false
    // if any children are different return false

    const isAlteredParentOrSingle = !Object.entries(newRequirement).every(([ key, value ]) => {
      if (key === 'childrenRequiredValues') return true;
      return prevRequirements[key as keyof FormattedRequirementParams] == value;
    });

    const alteredChildren: SingleChildFormattedRequirement[] = [];
    if (newRequirement.childrenRequiredValues?.length) {
      const prevChildRequirements = prevRequirements.childrenRequiredValues as SingleChildFormattedRequirement[] || [];

      newRequirement.childrenRequiredValues.forEach((childReq) => {
        const foundPrevChildReq = prevChildRequirements.find((prevChildReq) => prevChildReq.requirementId == childReq.requirementId);

        if (!foundPrevChildReq) {
          throw new Error('Child requirement not found in previous requirements');
        }

        const sameRequiredValue = Number(foundPrevChildReq.requiredValue) == Number(childReq.requiredValue);

        if (!sameRequiredValue) {
          alteredChildren.push(childReq);
        }
      });
    }


    return {
      isAlteredParentOrSingle,
      alteredChildren,
    }
  }

  handleRequirementProps(requirementsArr: FormattedRequirementParams[]) {
    const prevRequirements = this.prevValues?.requirementsArr;

    const formattedNewArray: FormattedRequirementParams[] = [];
    const formattedUpdateArray: FormattedRequirementParams[] = [];

    const removalArray = prevRequirements?.filter((prevReq) => requirementsArr.every((newReq) => prevReq.requirementId != newReq.requirementId)) || [];

    requirementsArr.forEach(newReq => {
      const foundPrevReq = prevRequirements?.find((prevReq) => prevReq.requirementId == newReq.requirementId);

      if (!foundPrevReq) {
        formattedNewArray.push(newReq);
        return;
      }

      const { alteredChildren, isAlteredParentOrSingle } = this.checkRequirementEqualityWithPrevious(newReq, foundPrevReq);

      if (isAlteredParentOrSingle) {
        delete newReq?.childrenRequiredValues;
        formattedUpdateArray.push(newReq);
      }

      if (alteredChildren.length > 0) {
        formattedUpdateArray.push(...alteredChildren);
      }
    });

    const adjustedRequirementsObj: {
      addRequirementsArr?: FormattedRequirementParams[],
      updateRequirementsArr?: FormattedRequirementParams[],
      removeRequirementsArr?: number[],
    } = {};


    if (removalArray?.length > 0) {
      const removalIds = removalArray?.map((req) => {
        const userReqId = req.userRequirementId;

        if (!userReqId) {
          throw new Error('User requirement id not found for removing requirement');
        }

        return Number(userReqId);
      });

      adjustedRequirementsObj['removeRequirementsArr'] = removalIds;
    }

    if (formattedNewArray.length > 0) {
      adjustedRequirementsObj['addRequirementsArr'] = formattedNewArray;
    }

    if (formattedUpdateArray.length > 0) {
      adjustedRequirementsObj['updateRequirementsArr'] = formattedUpdateArray;
    }

    return adjustedRequirementsObj;
  }


  comparePropsWithPrevious(currentProps: CreateUserRequestAttributes) {
    const prevProps = this.prevValues;

    const finalUpdateProps: UpdateUserRequestAttributes = Object.entries(currentProps).reduce((acc, [ key, value ]) => {
      const isArray = Array.isArray(value);
      const isObject = typeof value === 'object' && value !== null;
      const isIdArray = arrayProps.includes(key as ArrayProps);
      const isComplex = isArray || isObject;
      const isRequirement = key === 'requirementsArr';

      if (!isComplex) {
        // @ts-ignore
        if (value == prevProps?.[key]) return acc;

        return { ...acc, [key]: value };
      }

      if (isIdArray) {
        return { ...acc, ...this.handleArrayProps(key as ArrayProps, value) };
      } else if (isRequirement) {
        return { ...acc, ...this.handleRequirementProps(value) };
      }

      return acc;
    }, { });

    return finalUpdateProps;
  }

  // #endregion COMPARE PROPS

  filterProps(formattedProps: CreateUserRequestAttributes): Partial<CreateUserRequestAttributes> {
    const returnObj: Partial<CreateUserRequestAttributes> = {};

    return Object.entries(formattedProps).reduce((acc, [ key, value ]) => {
      const isArray = Array.isArray(value);

      if (isArray && value.length === 0) return acc;
      if (!isArray && (value === '' || value === undefined)) return acc;

      return { ...acc, [key]: value };
    }, returnObj);
  }

  generateRequest() {
    const formattedRequest = this.formatPropsForRequest();

    if (!this.prevValues) {
      formattedRequest.userLoginName = this.contactEmail;

      return this.filterProps(formattedRequest);
    }

    const finalUpdateProps = this.comparePropsWithPrevious(formattedRequest);

    return finalUpdateProps;
  }

  // #endregion GENERATE REQUESTS
}

export default UserGenerator;
