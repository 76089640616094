import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UsersService {
  private _usersData$ = new BehaviorSubject<any>(null);
  public readonly usersData$ = this._usersData$.asObservable();

  private _selectedUserData$ = new BehaviorSubject<any>(null);
  public readonly selectedUserData$ = this._selectedUserData$.asObservable();

  user: any;

  constructor(private http: HttpClient, private authService: AuthService) {
    this.authService.user$.subscribe((user: any) => this.user = user)
  }

  getUserById = (userId: number, additionalParameters?: any) => {
    return this.http.get(`${environment.expressUrl}/users/${userId}`,
      {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params: { 'loginName': this.user.loginName, ...additionalParameters },
      }).subscribe({
      next: (response: any) => {
        this._selectedUserData$.next(response.data);
      },
      error: (error) => {
        console.log('error', error);
      },
    })
  }

  clearSelectedUser = () => {
    this._selectedUserData$.next(null);
  }

  getUsers = ({
    userNameFilter, activeFlag = 1, sortColumn = 'tag', sortDirection = 'ASC', limit, offset = 0, includedAssociations,
  }: {
    userNameFilter: string;
    activeFlag: 1 | 0;
    sortColumn: string;
    sortDirection: 'ASC' | 'DESC';
    limit: number;
      offset: number;
    includedAssociations: string;
    }) => {

    const params: any = {
      loginName: this.user.loginName,
      activeFlag,
      includedAssociations,
    }

    if (userNameFilter) {
      params.userNameFilter = userNameFilter;
    }

    if (limit !== 0) {
      params.limit = limit;
      params.offset = offset;
    }

    if (sortColumn) {
      params.sortColumnName = sortColumn;
      params.sortColumnDirection = sortDirection;
    }

    return this.http.get(`${environment.expressUrl}/users`,
      {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params,
      }).subscribe({
      next: (response: any) => {
        this._usersData$.next(response.data);
      },
      error: (error) => {
        console.log('error', error);
      },
    })
  }

  getUsersFilteredByName = (searchObject: any) => {
    return this.http.get(`${environment.expressUrl}/users`,
      {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params: {
          'loginName': this.user.loginName,
          'limit': 25,
          'activeFlag': searchObject.showActiveItems ? 1 : 0,
          'userNameFilter': searchObject.searchText,
          'includedAssociations': 'PrimaryBuilding, PrimaryPosition',
        },
      }).subscribe({
      next: (response: any) => {
        this._usersData$.next(response.data);
      },
      error: (error) => {
        this._usersData$.next(error);
        console.log('error', error);
      },
    })
  }

  // TODO: add licenceAndCertsArr to the post request once the endpoint is available
  addUser = (requestBody: any) => {
    return this.http.post(
      `${environment.expressUrl}/users`,
      {
        loginName: this.user.loginName,
        ...requestBody,
      },
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    )
  }

  // TODO: add license and certs once endpoint is available
  updateUser = (requestBody: any, userId: number) => {
    // TODO: maybe add another permission check here, as we are primarily disabling fields in the UI
    return this.http.put(
      `${environment.expressUrl}/users/${userId}`,
      {
        loginName: this.user.loginName,
        ...requestBody,
      },
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    )
  }

  getInactiveUsers = (
    includedAssociations: string, limit = 25, offset = 0,
  ) => {
    return this.http.get(`${environment.expressUrl}/users`,
      {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params: {
          'loginName': this.user.loginName,
          'limit': limit,
          'offset': offset,
          'activeFlag': 0,
          'includedAssociations': includedAssociations,
        },
      }).subscribe({
      next: (response: any) => {
        this._usersData$.next(response.data);
      },
      error: (error) => {
        console.log('error', error);
      },
    })
  }

  getPagination = (pageDirection: any) => {
    let links = '';
    let pagination = {
      curPage: 0, totalItems: 0, totalPages: 0,
    };
    this.usersData$.subscribe(data => {
      links = data.links;
      pagination = data.pagination;
    });

    if (links[pageDirection] === '') {
      return;
    }
    return this.http.get(`${environment.expressUrl}/users/` + links[pageDirection],
      { headers: { 'Content-Type': 'application/vnd.api+json' } })
      .subscribe({
        next: (response: any) => {
          this._usersData$.next(response.data);
          console.log('response', response);
        },
        error: (error) => {
          console.log('error', error);
        },
      })
  }

}
