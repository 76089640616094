import {
  Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import UserGenerator from 'src/app/classes/user.class';

@Component({
  selector: 'app-user-assignments',
  templateUrl: './user-assignments.component.html',
  styleUrls: [ './user-assignments.component.scss' ],
})
export class UserAssignmentsComponent {
  @ViewChild('administratorinput') administratorInput: any;
  @ViewChild('supervisorinput') supervisorInput: any;
  @Output() updateUserObject = new EventEmitter<object>();
  @Output() stepBack = new EventEmitter<any>();
  @Input() isEditMode: boolean;
  @Input() user: UserGenerator;
  @Input() secondaryApprovers: any;
  @Input() primaryApprovers: any;
  @Input() step: { text: string; completed: boolean; modified: boolean; }

  filteredPrimaryApprovers: any;
  filteredSecondaryApprovers: any;

  administrators: any;

  constructor(public dialog: MatDialog) { }

  ngOnInit() {
    this.filterApprovers(true);
    this.filterApprovers(false);
  }

  ngOnChanges(changes: SimpleChanges) {
    const primaryApprovers = changes['primaryApprovers'];
    const secondaryApprovers = changes['secondaryApprovers'];

    if (primaryApprovers?.currentValue) {
      this.filterApprovers(true);
    }

    if (secondaryApprovers?.currentValue) {
      this.filterApprovers(false);
    }

  }

  clearSelection() {
    //this resets the input value to blank
    this.administratorInput.nativeElement.value = '';
    this.supervisorInput.nativeElement.value = '';
  }

  filterApprovers(isPrimary: boolean) {
    const approverList = isPrimary ? this.primaryApprovers : this.secondaryApprovers;
    const selectedApproverIds = isPrimary ? this.user.primaryApproverIds : this.user.secondaryApproverIds;

    if (!approverList) return;

    const filteredApprovers = approverList.filter((approver: any) => {
      const isFound = !!selectedApproverIds.find((id: number) => id == approver.userId);
      return !isFound;
    });

    if (isPrimary) {
      this.filteredPrimaryApprovers = filteredApprovers;
    } else {
      this.filteredSecondaryApprovers = filteredApprovers;
    }
  }

  getApproverName = (approverId: number, isPrimary = true) => {
    const list = isPrimary ? this.primaryApprovers : this.secondaryApprovers;

    if (!list) return;

    const approver = list.find((item: any) => item.userId == approverId);

    return approver.fullName;
  }

  addApprovers = (event: Event, isPrimary = true) => {
    const selectedValue = Number((
      event.target as HTMLInputElement
    ).value);

    this.clearSelection();

    const userList = isPrimary ? this.user.primaryApproverIds : this.user.secondaryApproverIds;
    const isAlreadySelected = !!userList.find((approverId: any) => approverId == selectedValue);

    if (isAlreadySelected) return;

    const approverList = isPrimary ? this.primaryApprovers : this.secondaryApprovers;

    const selectedApprover = approverList.find((approver: any) => approver.userId == selectedValue);

    if (!selectedApprover) return;

    if (isPrimary) {
      this.user.primaryApproverIds = [ ...this.user.primaryApproverIds, Number(selectedApprover.userId) ];
      this.filterApprovers(true);
    } else {
      this.user.secondaryApproverIds = [ ...this.user.secondaryApproverIds, Number(selectedApprover.userId) ];
      this.filterApprovers(false);
    }

    this.step.modified = true;
  }

  removeApprover = (approverId: number, isPrimary = true) => {
    const list = isPrimary ? this.user.primaryApproverIds : this.user.secondaryApproverIds;

    const newList = list.filter((id: number) => id !== approverId);

    if (isPrimary) {
      this.user.primaryApproverIds = newList;
      this.filterApprovers(true);
    } else {
      this.user.secondaryApproverIds = newList;
      this.filterApprovers(false);
    }


  }


  stepBackward = () => {
    this.stepBack.emit();
  }

  closeDialog = () => {
    this.dialog.closeAll();
  }

  updateUser = () => {
    const user = this.user;
    const primaryIds = user.primaryApproverIds;
    const secondaryIds = user.secondaryApproverIds;

    if (primaryIds.length === 0 || secondaryIds.length === 0) {
      return;
    }
    this.step.completed = true;
    this.updateUserObject.emit();
  }

}
