<div
  #modalContainer
  class="modal-background"
  *ngIf="displayModal"
  (click)="closeModalHandler()"
>
  <div class="modal-container" (click)="$event.stopPropagation()">
    <div class="header">
      <div class="title">
        <app-icon>{{ headerIcon }}</app-icon>
        <h3>{{ title }}</h3>
      </div>

      <app-new-button
        (clickHandler)="closeModalHandler()"
        leftIcon="close"
        size="md"
        color="bare"
        customPadding="3px"
      ></app-new-button>
    </div>
    <div class="content">
      <ng-container *ngIf="!isLoading">
        <div class="fixed-content">
          <ng-content select="[fixedHeader]"></ng-content>
        </div>
        <div class="scroll-content">
          <ng-content></ng-content>
        </div>
      </ng-container>

      <app-loading-spinner
        *ngIf="isLoading"
        class="loading-spinner"
      ></app-loading-spinner>
    </div>

    <div class="footer">
      <ng-content select="[footer]"></ng-content>
    </div>
    <div class="footer footer-default">
      <app-new-button
        (clickHandler)="closeModalHandler()"
        size="sm"
        color="tonal"
        customPadding="12px 16px"
        >Close</app-new-button
      >
    </div>
  </div>
</div>
<!-- (clickHandler)="closeModalHandler()" -->
