import {
  Component, EventEmitter, Input, Output, OnInit, OnChanges, SimpleChanges,
} from '@angular/core';
@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: [ './pagination.component.scss' ],
})
export class PaginationComponent implements OnInit, OnChanges {
  @Output() paginationEvent = new EventEmitter<any>();
  @Input() isLoading = false;
  @Input() tableData: any;
  @Input() selectedItemsPerPage = 25;
  @Input() showActiveItems = true;
  @Output() itemsPerPageChange = new EventEmitter<number>();
  @Output() pageChanged = new EventEmitter<number>();
  @Input() useOldTableStyling = false;


  pager: any = {};
  isDropdownOpen = false;
  prevLink = true;
  nextLink = true;
  links: any;
  selected: string;

  selectItemsPerPage(itemsPerPage: number) {
    this.selectedItemsPerPage = itemsPerPage;
    this.isDropdownOpen = false;
    this.pager.pageSize = itemsPerPage;
    this.pager.currentPage = 0;
    this.setPage(this.pager.currentPage);
    this.itemsPerPageChange.emit(itemsPerPage);
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  tab(page: number) {
    this.pageChanged.emit(page);
    this.setPage(page);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['tableData'] && this.tableData) {
      this.setPage(this.pager.currentPage);
    }
  }

  ngOnInit() {
    if (this.tableData) {
      this.setPage(this.pager.currentPage);
    }
  }

  setPage(page: number, command?: string) {
    const targetPage = page;
    const totalItems = (this.showActiveItems ? this.tableData.activeTotal : this.tableData.inactiveTotal) || this.tableData.meta?.totalItems;

    this.pager = this.getPager(
      totalItems, targetPage, this.selectedItemsPerPage,
    );

    if (command && [
      'first', 'prev', 'next', 'last',
    ].includes(command)) {
      this.pageChanged.emit(this.pager.currentPage);
    } else if (command) {
      console.error('Invalid page command:', command);
      return;
    } else {
      const newPage = this.getPager(
        totalItems, targetPage, this.selectedItemsPerPage,
      ).currentPage;
      if (newPage !== this.pager.currentPage) {
        this.pager = this.getPager(
          totalItems, targetPage, this.selectedItemsPerPage,
        );
      }
    }
  }



  getPager(
    totalItems: number, currentPage = 1, pageSize = this.selectedItemsPerPage,
  ): any {
    // Calculate total pages dynamically
    const totalPages = Math.ceil(totalItems / pageSize);

    let startPage: number, endPage: number;

    if (totalPages <= 10) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }

    const length = endPage + 1 - startPage;
    const pages = length > 0 ? Array.from(Array(length).keys()).map(i => startPage + i) : [];

    return {
      totalItems,
      currentPage,
      pageSize,
      totalPages,
      startPage,
      endPage,
      pages,
    };
  }


  paginationClick = (pageDirection: string) => {
    this.paginationEvent.emit(pageDirection);
    this.links = this?.tableData?.links;
  }

}
