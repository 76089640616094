import {
  Component, Inject, OnDestroy, OnInit,
} from '@angular/core';
import {
  MAT_DIALOG_DATA, MatDialog, MatDialogRef,
} from '@angular/material/dialog';
import { ApproversService } from 'src/app/services/approvers.service';
import { AuthService } from 'src/app/services/auth.service';
import { EntityService } from 'src/app/services/entity.service';
import { UsersService } from 'src/app/services/users.service';
import { RequirementsService } from 'src/app/services/requirements.service';
import UserGenerator from 'src/app/classes/user.class';

@Component({
  selector: 'app-update-user',
  templateUrl: './update-user.component.html',
  styleUrls: [ './update-user.component.scss' ],
})
export class UpdateUserComponent implements OnInit, OnDestroy {
  isAdminUser = false;

  generalStep = {
    completed: false, text: 'General Info', modified: false,
  };
  buildingsAndPositionsStep = {
    completed: false, text: 'Buildings & positions', modified: false,
  };
  userAssignmentsStep = {
    completed: false, text: 'User assignments', modified: false,
  };
  requirementsStep = {
    completed: false, text: 'Requirements', modified: false,
  };
  licensesAndCertsStep = {
    completed: false, text: 'Licenses & certs', modified: false,
  };
  reviewAndFinishStep = {
    completed: false, text: 'Review & finish', modified: false,
  };

  basicUserSteps = [
    this.generalStep,
    // this.licensesAndCertsStep,
    this.reviewAndFinishStep,
  ]

  adminSteps = [
    this.generalStep,
    this.buildingsAndPositionsStep,
    this.userAssignmentsStep,
    this.requirementsStep,
    // this.licensesAndCertsStep,
    this.reviewAndFinishStep,
  ]

  steps: { completed: boolean; text: string; modified: boolean }[] = []

  formattedUserInfo = new UserGenerator();

  selectedStep: any = {};
  editedUser: UserGenerator;
  entity: any;
  requirements: any;
  buildings: any;
  positions: any;
  roles: any;
  users: any;
  primaryApprovers: any;
  secondaryApprovers: any;
  originalUser: any;
  isLoading = false;
  isEditMode = false;
  permissions: any;
  requirementsData: any;

  // #region Subscriptions
  authSubscription = this.authService.user$.subscribe((user: any) => {
    if (!user) return;
    const permissions = user.Permissions;
    this.permissions = permissions

    const hasAdd = permissions.USER_ADD === 1;
    const hasEdit = permissions.USER_EDIT === 1;

    if (this.data && hasEdit) {
      this.steps = this.adminSteps
      this.isAdminUser = true;
    } else if (!this.data && hasAdd) {
      this.steps = this.adminSteps
      this.isAdminUser = true;
    } else if (!this.data && !hasAdd) {
      this.dialogRef.close();
    } else if (this.data && !hasEdit && user.id == this.data.id) {
      // NOTE - add back in once reworked
      this.dialogRef.close();
      // this.steps = this.basicUserSteps;
      // this.isAdminUser = false;
    } else {
      this.dialogRef.close();
    }

    this.selectedStep = this.steps[0];
  });
  entitySubscription = this.entityService.entityData$.subscribe((entityData: any) => {
    this.entity = entityData
    // get the active buildings
    const activeBuildings = entityData?.Buildings?.filter((building: any) => building.activeFlag)
    this.buildings = activeBuildings?.sort((a: any, b: any) => a.buildingName.localeCompare(b.buildingName))
    // get the active positions
    const activePositions = entityData?.Positions?.filter((position: any) => position.activeFlag)
    this.positions = activePositions?.sort((a: any, b: any) => a.positionTitle.localeCompare(b.positionTitle))
    // get the active roles
    const activeRoles = entityData?.Roles?.filter((role: any) => role.activeFlag)
    this.roles = activeRoles
    if (this.roles?.length > 0) {
      if (!this.isEditMode || this.editedUser?.id) {
        this.isLoading = false
      }
    }
  });

  userSubscription = this.usersService.selectedUserData$.subscribe((user: any) => {
    if (!user) return;
    // we save the original user for comparison purposes
    this.originalUser = user
    this.editedUser = new UserGenerator(this.originalUser);

    user ? this.isLoading = false : this.isLoading = true
  });

  primaryApproverSubscription = this.approversService.primaryApproversData$.subscribe((primaryApproversData: any) => {
    this.primaryApprovers = primaryApproversData

  });
  secondaryApproverSubscription = this.approversService.secondaryApproversData$.subscribe((secondaryApproversData: any) => {
    this.secondaryApprovers = secondaryApproversData
  });

  requirementSubscription = this.requirementsService.requirementsData$.subscribe((requirementData: any) => {
    if (!requirementData) return;

    const activeRequirements = requirementData?.rows?.map((requirement: any) => {
      return {
        name: requirement.requirement,
        id: requirement.requirementId,
        requirement: requirement.requirement,
        parentId: requirement.parentRequirementId,
        topMostParentId: requirement.topMostParentId,
        topMostParentName: requirement.topMostParentName,
        nodeType: requirement.nodeType,
        trackingValue: requirement.Requirement.trackingValue,
        trackingUom: requirement.Requirement.trackingUom,
        rollingFlag: requirement.Requirement.rollingFlag,
        childCount: requirement.childCount,
        children: requirement?.Children?.map((requirement: any) => {
          return {
            name: requirement.requirement,
            id: requirement.requirementId,
            requirement: requirement.requirement,
            parentId: requirement.parentRequirementId,
            topMostParentId: requirement.topMostParentId,
            topMostParentName: requirement.topMostParentName,
            nodeType: requirement.nodeType,
            trackingValue: requirement.Requirement.trackingValue,
            trackingUom: requirement.Requirement.trackingUom,
            rollingFlag: requirement.Requirement.rollingFlag,
            childCount: requirement.childCount,
            children: requirement?.Children?.map((requirement: any) => {
              return {
                name: requirement.requirement,
                id: requirement.requirementId,
                requirement: requirement.requirement,
                parentId: requirement.parentRequirementId,
                topMostParentId: requirement.topMostParentId,
                topMostParentName: requirement.topMostParentName,
                nodeType: requirement.nodeType,
                trackingValue: requirement.Requirement.trackingValue,
                trackingUom: requirement.Requirement.trackingUom,
                rollingFlag: requirement.Requirement.rollingFlag,
                childCount: requirement.childCount,
                children: null,
              }
            }),
          }
        }),
      }
    })

    const sortedActiveRequirements = activeRequirements.sort((a: any, b: any) => a.requirement.localeCompare(b.requirement))
    this.requirements = sortedActiveRequirements;
  });

  // #endregion Subscriptions

  constructor(
    private entityService: EntityService,
    private requirementsService: RequirementsService,
    private authService: AuthService,
    private usersService: UsersService,
    private approversService: ApproversService,
    public dialogRef: MatDialogRef<UpdateUserComponent>,
    public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  // TODO: implement PIC number, user role, administrators, supervisors, and name changed flag once the endpoints are available and we have clarity
  ngOnInit(): void {
    // if we have incoming data, we are in edit mode
    if (this.data) {
      this.isLoading = true
      this.isEditMode = true;
      // here we clear any previous user data and get the user by id
      this.usersService.clearSelectedUser()
      this.usersService.getUserById(this.data.id, { 'includedAssociations': 'CreationProps' })
    } else {
      this.editedUser = new UserGenerator();
    }
    // in order to get full lists of buildings, positions, and users, we need to get them through the entity. we use these in different dropdowns throughout the user update process
    this.isLoading = true
    this.requirementsService.getRequirementsChildrenIncluded();
    this.entityService.getEntity('Requirements, Buildings, Positions, Roles');
    this.approversService.getPrimaryApprovers();
    this.approversService.getSecondaryApprovers();
  }

  stepBack = () => {
    const currentIndex = this.steps.findIndex((step: any) => step === this.selectedStep)
    this.selectedStep = this.steps[currentIndex - 1]
  }

  changeStep = (step: any) => {
    this.selectedStep = step
  }

  closeDialog = () => {
    this.dialogRef.close();
  }

  updateUser = () => {
    const currentIndex = this.steps.findIndex((step: any) => step === this.selectedStep)
    this.steps[currentIndex].completed = true;
    this.selectedStep = this.steps[currentIndex + 1]
  }

  ngOnDestroy(): void {
    this.entitySubscription.unsubscribe();
    this.primaryApproverSubscription.unsubscribe();
    this.secondaryApproverSubscription.unsubscribe();
    this.userSubscription?.unsubscribe();
    this.authSubscription.unsubscribe();
  }

}
