<div class="content-container">
  <div class="title-row">
    <h1>{{ title }}</h1>
    <div class="title-buttons" *ngIf="titleButtonGroup?.length">
      <ng-container *ngFor="let button of titleButtonGroup">
        <app-new-button
          class="button"
          [color]="button.color"
          [size]="button.size"
          [leftIcon]="button?.leftIcon"
          [rightIcon]="button?.rightIcon"
          [elevated]="button.elevated"
          [disabled]="button.disabled"
          (clickHandler)="clickHandler(button.name)"
        >
          {{ button.display }}
        </app-new-button>
      </ng-container>
    </div>
  </div>

  <div class="content-area">
    <div class="header">
      <app-button-group
        *ngIf="contentNavOptions?.length"
        class="sub-navigation"
        [buttonGroup]="contentNavOptions || []"
      />
      <ng-content select="[fixedHeader]"> </ng-content>
    </div>
    <div class="content">
      <ng-content></ng-content>
    </div>
  </div>
</div>
