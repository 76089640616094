import {
  Component, EventEmitter, Input, Output,
  SimpleChanges,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: [ './modal.component.scss' ],
})
export class ModalComponent {
  @Input() displayModal = true;
  @Input() isLoading = false;
  @Input() title = '';
  @Input() headerIcon = '';

  @Output() closeModal = new EventEmitter<unknown>();

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges) {
    const displayChanges = changes['displayModal'];
  }


  stopBubble(event: Event) {
    event.stopPropagation();
  }

  closeModalHandler() {
    console.log('Close modal');
    this.closeModal.emit();
    this.displayModal = false;
  }

  ngOnDestroy() {
    console.log('Destroying modal');
  }

}
