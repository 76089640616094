<div class="container">
  <span class="klabel-medium label" *ngIf="label">{{ label }}</span>
  <div class="input-container">
    <!-- [value]="value"  -->
    <input
      [(ngModel)]="value"
      #localInput="ngModel"
      [required]="required"
      [email]="email"
      [disabled]="disabled"
      [ngClass]="{
        'left-padding': icon,
        'right-padding': iconSide === 'right',
        white: color === 'white'
      }"
      (keyup)="updateInput($event)"
      class="input kplaceholder"
      [type]="type || 'text'"
      [min]="minimum || 0"
      [max]="maximum"
      [placeholder]="this.placeholder"
    />
    <mat-icon
      class="icon"
      fontSet="material-icons-round"
      [ngClass]="{ 'right-side': iconSide === 'right' }"
      >{{ icon }}</mat-icon
    >
  </div>
  <span
    class="klabel-medium error"
    *ngIf="localInput.touched && localInput.invalid"
    >This is a {{ required && "required" }} {{ email && "email" }} field</span
  >
  <span *ngIf="error" class="klabel-medium error">{{ error }}</span>
</div>
