import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { DashboardComponent } from './routes/dashboard/dashboard.component';
import { SignInComponent } from './routes/sign-in/sign-in.component';
import { LoggedOutGuard } from './guards/logged-out.guard';
import { SystemSettingsComponent } from './routes/system-settings/system-settings.component';
import { ManageStaffComponent } from './routes/manage-staff/manage-staff.component';
import { CourseCatalogComponent } from './routes/manage-courses/components/course-catalog/course-catalog.component';
import { ProfileComponent } from './routes/profile/profile.component';
import { ValidationComponent } from './routes/manage-courses/components/validation/validation.component';
import { CourseRequestsComponent } from './routes/manage-courses/components/course-requests/course-requests.component';
import { ManageCoursesComponent } from './routes/manage-courses/manage-courses.component';
import { ValidateCoursesGuard } from './guards/validate-courses.guard';
import { SystemSettingsGuard } from './guards/system-settings.guard';
import { ManageStaffGuard } from './guards/manage-staff.guard';
import { StateDocumentationExportComponent } from './routes/manage-courses/components/state-documentation-export/state-documentation-export.component';
import { PermissionGuard } from './guards/permission.guard';
import { FromMichiganGuard } from './guards/from-michigan.guard';

const routes: Routes = [
  {
    path: 'signin', component: SignInComponent, canActivate: [ LoggedOutGuard ],
  },
  {
    path: 'system-settings',
    component: SystemSettingsComponent,
    canActivate: [
      AuthGuard,
      PermissionGuard([
        'SYS_SETTINGS_DEFAULTS',
        'SYS_SETTINGS_TYPES',
        'SYS_SETTINGS_CATEGORIES',
        'SYS_SETTINGS_REQUIREMENTS',
        'SYS_SETTINGS_BUILDINGS',
        'SYS_SETTINGS_TAGS',
        'SYS_SETTINGS_ROLES',
        'SYS_SETTINGS_POSITIONS',
      ]),
    ],
  },
  // {
  //   path: 'dashboard', component: DashboardComponent, canActivate: [ AuthGuard ],
  // },
  // TODO - add above line back in and remove lower when dashboard is ready
  { path: 'dashboard', redirectTo: '/profile' },
  {
    path: 'profile/:id', component: ProfileComponent, canActivate: [ AuthGuard ],
  },
  {
    path: 'profile', component: ProfileComponent, canActivate: [ AuthGuard ],
  },
  {
    path: 'manage-staff',
    component: ManageStaffComponent,
    canActivate: [ AuthGuard, PermissionGuard('USER_ACCOUNTS') ],
  },
  {
    path: 'manage-courses',
    canActivate: [ AuthGuard ],
    children: [
      {
        path: 'index', component: ManageCoursesComponent, canActivate: [ AuthGuard ],
      },
      {
        path: 'validation',
        component: ValidationComponent,
        canActivate: [ AuthGuard, PermissionGuard('VALIDATE_ATTENDANCE') ],
      },
      {
        path: 'course-catalog', component: CourseCatalogComponent, canActivate: [ AuthGuard ],
      },
      {
        path: 'course-requests', component: CourseRequestsComponent, canActivate: [ AuthGuard ],
      },
      {
        path: 'documentation-export',
        component: StateDocumentationExportComponent,
        canActivate: [
          AuthGuard,
          FromMichiganGuard,
          PermissionGuard('EXPORT_STATE_DOC'),
        ],
      },
    ],
  },
  {
    path: 'home', redirectTo: '/profile', pathMatch: 'full',
  },
  {
    path: '', redirectTo: '/signin', pathMatch: 'full',
  },
  { path: '**', redirectTo: '/signin' },
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ],
})
export class AppRoutingModule { }
